import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EAppState, EDebateFormat, EDebateSpice } from '../../model/enum';
import { ITrendingDebate } from '../../model/interface';
import { EnvironmentHelper } from '../../helpers/Environment';

interface IAppState {
    startupSeen: boolean;
    appState: EAppState;
    defaultSpice: EDebateSpice;
    defaultFormat: EDebateFormat;
    errorMessage?: string;
    warningMessage?: string;
    successMessage?: string;
    recentTopic?: string;
    trendingDebates?: ITrendingDebate[];
}

const DEFAULT_SPICE = 'defaultSpice';

const initialState: IAppState = {
    startupSeen: false,
    appState: EAppState.INITIALIZING,
    defaultSpice:
        (localStorage.getItem(DEFAULT_SPICE) as EDebateSpice) ||
        (EnvironmentHelper.parseAsBoolean(process.env.REACT_APP_SHOCKING)
            ? EDebateSpice.MEDIUM
            : EDebateSpice.MILD),
    defaultFormat: EDebateFormat.STANDARD,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setStartupSeen(state) {
            state.startupSeen = true;
        },
        setDefaultSpice(state, action: PayloadAction<EDebateSpice>) {
            state.defaultSpice = action.payload || EDebateSpice.MEDIUM;
            localStorage.setItem(DEFAULT_SPICE, state.defaultSpice);
        },
        setTrendingDebates(state, action: PayloadAction<ITrendingDebate[]>) {
            state.trendingDebates = action.payload;
        },
        setDefaultFormat(state, action: PayloadAction<EDebateFormat>) {
            state.defaultFormat = action.payload || EDebateFormat.STANDARD;
        },
        setAppState(state, action: PayloadAction<EAppState>) {
            state.appState = action.payload;
        },
        setErrorMessage(state, action: PayloadAction<string | undefined>) {
            state.errorMessage = action.payload;
        },
        setWarningMessage(state, action: PayloadAction<string | undefined>) {
            state.warningMessage = action.payload;
        },
        setSuccessMessage(state, action: PayloadAction<string | undefined>) {
            state.successMessage = action.payload;
        },
        setRecentTopic: (state, action: PayloadAction<string | undefined>) => {
            state.recentTopic = action.payload;
        },
    },
});

export const {
    setStartupSeen,
    setDefaultSpice,
    setTrendingDebates,
    setDefaultFormat,
    setAppState,
    setErrorMessage,
    setWarningMessage,
    setSuccessMessage,
    setRecentTopic,
} = appSlice.actions;
export default appSlice.reducer;
