import React from 'react';
import Box from '@mui/material/Box';

import { IParticipant } from '../../../model/interface';
import { EDebatePosition } from '../../../model/enum';
import { ImageHelper } from '../../../helpers/Image';

interface ITopicsProps {
    speaker: IParticipant;
    backgroundColor: string;
    speaking?: boolean;
    buffering?: boolean;
    mouthClosed?: boolean;
    size: 'small' | 'medium' | 'large';
}

const MODERATOR_SIZE_FACTOR = 0.75;

const AvatarComponent: React.FC<ITopicsProps> = ({
    speaker,
    backgroundColor,
    speaking,
    buffering,
    mouthClosed,
    size,
}) => {
    const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
    // const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    // const blinkRef = useRef<boolean>(false);

    // const triggerRandomEvent = () => {
    //     const delay =
    //         // blinkRef.current
    //         // ? 500 :
    //         Math.floor(Math.random() * 250) + 250; // Math.floor(0.1 * Math.random() * (speaking ? 2000 : 3000)) + 500;
    //     blinkRef.current = !blinkRef.current;
    //     timeoutRef.current = setTimeout(triggerRandomEvent, delay);
    // };

    // const cancelTimeout = () => {
    //     if (timeoutRef.current) {
    //         clearTimeout(timeoutRef.current);
    //     }
    // };

    // useEffect(() => {
    //     triggerRandomEvent();
    //     console.log(speaker.imageUrls);
    //     return () => {
    //         cancelTimeout();
    //     };
    // }, []);

    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                sx={{
                    width: {
                        xs:
                            (size === 'large'
                                ? 192
                                : size === 'medium'
                                  ? 128
                                  : 64) *
                            (speaker.position === EDebatePosition.MODERATOR
                                ? MODERATOR_SIZE_FACTOR
                                : 1),
                        sm:
                            (size === 'large'
                                ? 192
                                : size === 'medium'
                                  ? 128
                                  : 64) *
                            (speaker.position === EDebatePosition.MODERATOR
                                ? MODERATOR_SIZE_FACTOR
                                : 1),
                        md:
                            (size === 'large'
                                ? 256
                                : size === 'medium'
                                  ? 128
                                  : 96) *
                            (speaker.position === EDebatePosition.MODERATOR
                                ? MODERATOR_SIZE_FACTOR
                                : 1),
                    },
                    backgroundColor,
                    borderRadius: '50%',
                    aspectRatio: 1,
                    overflow: 'hidden',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        aspectRatio: 1,
                        clipPath: 'circle(50%)',
                        opacity: imageLoaded ? 1 : 0,
                        position: 'relative',
                        top: imageLoaded ? 0 : '1rem',
                        transition:
                            'opacity 0.5s ease-in-out, top 0.3s ease-in-out',
                    }}
                    onLoad={() => setImageLoaded(true)}
                    alt={speaker.name}
                    src={
                        buffering
                            ? ImageHelper.prepareUrl(
                                  speaker.imageUrls.thinking ||
                                      speaker.imageUrls.default
                              )
                            : speaking && !mouthClosed
                              ? // blinkRef.current
                                //     ? ImageHelper.prepareUrl(
                                //           speaker.imageUrls.talkingBlink
                                //       )
                                //     :
                                ImageHelper.prepareUrl(
                                    speaker.imageUrls.talking
                                )
                              : // blinkRef.current
                                //   ? ImageHelper.prepareUrl(
                                //         speaker.imageUrls.defaultBlink
                                //     )
                                //   :
                                ImageHelper.prepareUrl(
                                    speaker.imageUrls.default
                                )
                    }
                />
            </Box>
        </Box>
    );
};

export default AvatarComponent;
