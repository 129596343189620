import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const participantTheme = createTheme(mainTheme, {
    typography: {
        h1: {
            fontSize: '2.6rem',
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '2.4rem',
            },
        },
        caption: {
            fontSize: '1rem',
            opacity: 0.8,
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '1.3rem',
            },
        },
        body1: {
            fontSize: '1.4rem',
            lineHeight: 1.2,
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '1.4rem',
            },
        },
    },
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    padding: '1.5rem',
                    [mainTheme.breakpoints.down('sm')]: {
                        paddingTop: '2rem',
                        paddingBottom: '2rem',
                    },
                    borderRadius: '0.4rem',
                },
            },
        },
    },
});

export default participantTheme;
