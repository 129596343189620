export class ArrayHelper {
    public static isEmpty = (array: any[] | undefined): boolean => {
        return !array || array.length === 0;
    };

    public static isNotEmpty = (array: any[] | undefined): boolean => {
        return !this.isEmpty(array);
    };

    public static shuffle = <T>(array: T[]): T[] => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    public static toUnique = <T>(array: T[]): T[] => {
        return array.filter(
            (value, index, self) => self.indexOf(value) === index
        );
    };
}
