import React from 'react';
import {
    Box,
    Button,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
} from '@mui/material';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { MONTH_NAMES } from '../../model/constant';
import { StringHelper } from '../../helpers/String';
import { updateDOB } from '../../redux/user/actions';
import { DateTimeHelper } from '../../helpers/DateTime';
import { Close } from '@mui/icons-material';
import { setDefaultSpice } from '../../redux/app/slice';
import { EDebateSpice } from '../../model/enum';

interface IProps {
    handleCloseClick: () => void;
}

const AgeGateComponent: React.FC<IProps> = ({ handleCloseClick }) => {
    const dispatch = useDispatch<AppDispatch>();

    const [year, setYear] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [day, setDay] = React.useState('');

    const handleDOBSubmit = async () => {
        const dob = DateTimeHelper.makeUnixtime(year, month, day);
        await dispatch(updateDOB(dob));
        if (DateTimeHelper.isOldEnough(dob)) {
            dispatch(setDefaultSpice(EDebateSpice.HOT));
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: 4 }}
        >
            <Typography variant="h3" align="center">
                Please&nbsp;enter&nbsp;your date&nbsp;of&nbsp;birth:
            </Typography>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
                sx={{ gap: 2 }}
            >
                <FormControl>
                    <InputLabel>Month</InputLabel>
                    <Select
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        sx={{ minWidth: 250 }}
                    >
                        {MONTH_NAMES.map((monthName, index) => (
                            <MenuItem key={index} value={index + 1}>
                                {monthName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Day</InputLabel>
                    <Select
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                        sx={{ minWidth: 140 }}
                    >
                        {Array.from({ length: 31 }, (_, i) => (
                            <MenuItem key={i} value={i + 1}>
                                {i + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Year</InputLabel>
                    <Select
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        sx={{ minWidth: 140 }}
                    >
                        {Array.from({ length: 100 }, (_, i) => (
                            <MenuItem key={i} value={2023 - i}>
                                {2023 - i}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Button
                variant="contained"
                onClick={handleDOBSubmit}
                disabled={
                    StringHelper.isEmpty(year) ||
                    StringHelper.isEmpty(month) ||
                    StringHelper.isEmpty(day)
                }
            >
                Submit
            </Button>
            <IconButton
                onClick={handleCloseClick}
                sx={{ position: 'fixed', top: '1rem', right: '1rem' }}
            >
                <Close />
            </IconButton>
        </Box>
    );
};

export default AgeGateComponent;
