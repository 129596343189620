import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { IDebate, IVotedDebate } from '../../../model/interface';
import { EPage } from '../../../model/enum';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    ThemeProvider,
    Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AppDispatch, RootState } from '../../../redux/store';
import { AppHelper } from '../../../helpers/App';
import { ArrayHelper } from '../../../helpers/Array';
import { UserHelper } from '../../../helpers/User';
import BusyComponent from '../../components/Busy';
import creditTheme from '../../../themes/credit';
import { DateTimeHelper } from '../../../helpers/DateTime';
import { DeleteForever, ExpandLess, ExpandMore } from '@mui/icons-material';
import { deleteDebate } from '../../../redux/debate/actions';
import dialogTheme from '../../../themes/dialog';

enum ESortBy {
    CREATED = 'created',
    TOPIC = 'topic',
    FORMAT = 'format',
    PROGRESS = 'progress',
    VOTES = 'votes',
}

const DebatesScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const [debates, setDebates] = useState<IVotedDebate[] | undefined>();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const [sortBy, setSortBy] = useState<ESortBy>(ESortBy.TOPIC);
    const [sortDir, setSortDir] = useState<boolean>(true);
    const [selectedDebate, setSelectedDebate] = useState<IVotedDebate>();
    const sortedDebates = debates?.sort((a, b) => {
        switch (sortBy) {
            case ESortBy.CREATED:
                return sortDir ? a.created - b.created : b.created - a.created;
            case ESortBy.TOPIC:
                return sortDir
                    ? a.topic.issue.localeCompare(b.topic.issue)
                    : b.topic.issue.localeCompare(a.topic.issue);
            case ESortBy.FORMAT:
                return sortDir
                    ? a.format.localeCompare(b.format)
                    : b.format.localeCompare(a.format);
            case ESortBy.PROGRESS:
                return sortDir
                    ? a.discussionLength - b.discussionLength
                    : b.discussionLength - a.discussionLength;
            case ESortBy.VOTES:
                return sortDir
                    ? a.votesTotal - b.votesTotal
                    : b.votesTotal - a.votesTotal;
            default:
                return 0;
        }
    });

    const handleDebateClick = (debate: IDebate | IVotedDebate) => {
        navigate(AppHelper.makeDebatePath(debate));
    };

    const handleDeleteClick = async (debate: IVotedDebate) => {
        setSelectedDebate(debate);
    };

    const handleDeleteDebate = async () => {
        const result = await dispatch(deleteDebate(selectedDebate!.key));
        if (result === true) {
            setDebates(
                debates?.filter((debate) => debate.key !== selectedDebate!.key)
            );
        }
        handleDialogClose();
    };

    const fetchDebates = async () => {
        try {
            const response = await axios.get<{ debates: IVotedDebate[] }>(
                `${apiUrl}/debates?${UserHelper.formatAuth(userAuth!)}`
            );
            const debates: IVotedDebate[] = response.data.debates;
            setDebates(
                debates.sort((a, b) =>
                    a.topic.issue.localeCompare(b.topic.issue)
                )
            );
        } catch (error) {
            console.error(error);
        } finally {
            //
        }
    };

    const handleDialogClose = () => {
        setSelectedDebate(undefined);
    };

    useEffect(() => {
        fetchDebates();
    }, [userAuth]);

    return sortedDebates === undefined ? (
        <BusyComponent />
    ) : (
        <>
            <Box
                display="flex"
                justifyContent="center"
                sx={{
                    paddingTop: { xs: '1rem', sm: '2rem' },
                    paddingBottom: { xs: '1rem', sm: '2rem' },
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        maxWidth: 1200,
                        gap: 4,
                    }}
                >
                    <Typography variant="h1">Debate History</Typography>
                    {ArrayHelper.isEmpty(sortedDebates) ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ gap: 5 }}
                        >
                            <Typography variant="body1">
                                You haven’t started nor voted on any yet.
                            </Typography>
                            <Button
                                variant="outlined"
                                onClick={() => navigate(EPage.CREATE)}
                            >
                                Start a debate
                            </Button>
                        </Box>
                    ) : (
                        <ThemeProvider theme={creditTheme}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Button
                                                    onClick={() => {
                                                        setSortBy(
                                                            ESortBy.CREATED
                                                        );
                                                        setSortDir(!sortDir);
                                                    }}
                                                    endIcon={
                                                        sortBy ===
                                                        ESortBy.CREATED ? (
                                                            sortDir ? (
                                                                <ExpandMore />
                                                            ) : (
                                                                <ExpandLess />
                                                            )
                                                        ) : undefined
                                                    }
                                                >
                                                    Created
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => {
                                                        setSortBy(
                                                            ESortBy.TOPIC
                                                        );
                                                        setSortDir(!sortDir);
                                                    }}
                                                    endIcon={
                                                        sortBy ===
                                                        ESortBy.TOPIC ? (
                                                            sortDir ? (
                                                                <ExpandMore />
                                                            ) : (
                                                                <ExpandLess />
                                                            )
                                                        ) : undefined
                                                    }
                                                >
                                                    Topic
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => {
                                                        setSortBy(
                                                            ESortBy.FORMAT
                                                        );
                                                        setSortDir(!sortDir);
                                                    }}
                                                    endIcon={
                                                        sortBy ===
                                                        ESortBy.FORMAT ? (
                                                            sortDir ? (
                                                                <ExpandMore />
                                                            ) : (
                                                                <ExpandLess />
                                                            )
                                                        ) : undefined
                                                    }
                                                >
                                                    Format
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    onClick={() => {
                                                        setSortBy(
                                                            ESortBy.PROGRESS
                                                        );
                                                        setSortDir(!sortDir);
                                                    }}
                                                    endIcon={
                                                        sortBy ===
                                                        ESortBy.PROGRESS ? (
                                                            sortDir ? (
                                                                <ExpandMore />
                                                            ) : (
                                                                <ExpandLess />
                                                            )
                                                        ) : undefined
                                                    }
                                                >
                                                    Stage
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    onClick={() => {
                                                        setSortBy(
                                                            ESortBy.VOTES
                                                        );
                                                        setSortDir(!sortDir);
                                                    }}
                                                    endIcon={
                                                        sortBy ===
                                                        ESortBy.VOTES ? (
                                                            sortDir ? (
                                                                <ExpandMore />
                                                            ) : (
                                                                <ExpandLess />
                                                            )
                                                        ) : undefined
                                                    }
                                                >
                                                    Votes
                                                </Button>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedDebates.map((row, index) => {
                                            let color:
                                                | 'success'
                                                | 'warning'
                                                | 'error' = 'error';
                                            let completeness =
                                                (row.discussionLength /
                                                    row.sequenceLength) *
                                                100;
                                            if (
                                                row.discussionLength ===
                                                row.sequenceLength
                                            ) {
                                                let pollCompleteness = 0;
                                                if (row.pre !== undefined) {
                                                    pollCompleteness++;
                                                }
                                                if (
                                                    row.openness !== undefined
                                                ) {
                                                    pollCompleteness++;
                                                }
                                                if (row.post !== undefined) {
                                                    pollCompleteness++;
                                                }
                                                completeness = Math.round(
                                                    (pollCompleteness / 3) * 100
                                                );

                                                if (pollCompleteness < 3) {
                                                    color = 'warning';
                                                } else {
                                                    color = 'success';
                                                }
                                            }
                                            return (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell>
                                                        <Typography
                                                            variant="body1"
                                                            align="left"
                                                        >
                                                            {DateTimeHelper.formatDate(
                                                                row.created
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            onClick={() =>
                                                                handleDebateClick(
                                                                    row
                                                                )
                                                            }
                                                        >
                                                            {row.topic.issue}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="body1"
                                                            align="left"
                                                        >
                                                            {AppHelper.getDebateSpiceName(
                                                                row.spice
                                                            )}
                                                            ,{' '}
                                                            {AppHelper.getDebateFormatName(
                                                                row.format
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CircularProgress
                                                            thickness={22}
                                                            variant="determinate"
                                                            color={color}
                                                            value={Math.max(
                                                                10,
                                                                completeness
                                                            )}
                                                            sx={{
                                                                position:
                                                                    'relative',
                                                                top: '0.3rem',
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography
                                                            variant="body1"
                                                            align="center"
                                                        >
                                                            {row.votesTotal}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.owner && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    handleDeleteClick(
                                                                        row
                                                                    )
                                                                }
                                                            >
                                                                <DeleteForever
                                                                    sx={{
                                                                        width: {
                                                                            xs: 24,
                                                                            sm: 24,
                                                                            md: 32,
                                                                        },
                                                                        height: {
                                                                            xs: 24,
                                                                            sm: 24,
                                                                            md: 32,
                                                                        },
                                                                        opacity: 0.35,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </ThemeProvider>
                    )}
                </Box>
            </Box>
            <ThemeProvider theme={dialogTheme}>
                <Dialog
                    open={selectedDebate !== undefined}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" variant="h1">
                        Delete this debate?
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <DialogContentText
                            id="alert-dialog-description"
                            variant="body1"
                            color="warning"
                        >
                            {selectedDebate?.topic.issue}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            You are about to permanently delete this debate. Do
                            you want to proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleDialogClose}>
                            Cancel
                        </Button>
                        <Button
                            color="error"
                            variant="contained"
                            onClick={handleDeleteDebate}
                            autoFocus
                        >
                            Yes, delete it
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </>
    );
};

export default DebatesScreen;
