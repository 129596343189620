import axios from 'axios';
import { ArrowBack, ArrowForward, Check, Poll } from '@mui/icons-material';
import {
    Box,
    Fab,
    IconButton,
    Paper,
    Typography,
    useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { DEBATE_ID_PARAM } from '../../../model/constant';
import { useEffect, useState } from 'react';
import { IDebateResult } from '../../../model/interface';
import { ColorHelper } from '../../../helpers/Color';
import { StringHelper } from '../../../helpers/String';
import { setDebateStage } from '../../../redux/debate/slice';
import { UserHelper } from '../../../helpers/User';
import { EPollType, EDebatePosition, EDebateStage } from '../../../model/enum';
import BusyComponent from '../../components/Busy';
import { LARGE_GAPS } from '../../../styles';

const ResultsScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const debate = useSelector((state: RootState) => state.debate.active);
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const polls = useSelector((state: RootState) => state.debate.polls);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const [results, setResults] = useState<IDebateResult | undefined>();
    const [busy, setBusy] = useState(true);

    const handleNextClick = () => {
        dispatch(setDebateStage(EDebateStage.SHARE));
    };

    const handleBackClick = () => {
        dispatch(setDebateStage(EDebateStage.EXIT_POLL));
    };

    useEffect(() => {
        const loadResults = async () => {
            const response = await axios.get<{ results: IDebateResult }>(
                `${apiUrl}/debate/results?${DEBATE_ID_PARAM}=${debate!.key}&${UserHelper.formatAuth(userAuth!)}`
            );
            if (response?.data?.results) {
                setResults(response.data.results);
            }
            setBusy(false);
        };
        loadResults();
    }, []);

    return busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={{
                xs: 'flex-start',
                sm: 'flex-start',
                md: 'center',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: LARGE_GAPS,
                    maxWidth: 960,
                }}
            >
                <Paper elevation={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ gap: 2 }}
                    >
                        <Poll />
                        <Typography variant="h1" align="center">
                            What do others think?
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="flex-end"
                            sx={{ gap: 2 }}
                        >
                            <Typography
                                variant="body1"
                                align="left"
                                sx={{ flex: 1 }}
                            >
                                “
                                {StringHelper.insertNonBreakingSpace(
                                    debate?.topic.position[
                                        EDebatePosition.SIDE_A
                                    ],
                                    false
                                )}
                                ”
                            </Typography>
                            <Typography
                                variant="body1"
                                align="right"
                                sx={{ flex: 1 }}
                            >
                                “
                                {StringHelper.insertNonBreakingSpace(
                                    debate?.topic.position[
                                        EDebatePosition.SIDE_B
                                    ],
                                    false
                                )}
                                ”
                            </Typography>
                        </Box>
                    </Box>
                    {results !== undefined && (
                        <>
                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={{ gap: 1, width: '100%' }}
                            >
                                <Box
                                    display="flex"
                                    sx={{
                                        height: 64,
                                        backgroundColor: '#000',
                                        padding: '0.5rem',
                                        position: 'relative',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flex:
                                                results.pre[
                                                    EDebatePosition.SIDE_A
                                                ].count -
                                                results.pre[
                                                    EDebatePosition.SIDE_A
                                                ].open,
                                            backgroundColor:
                                                ColorHelper.getColorGroup(
                                                    EDebatePosition.SIDE_A
                                                )![700],
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flex: results.pre[
                                                EDebatePosition.SIDE_A
                                            ].open,
                                            backgroundColor:
                                                ColorHelper.getColorGroup(
                                                    EDebatePosition.SIDE_A
                                                )![500],
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flex: results.pre[
                                                EDebatePosition.SIDE_B
                                            ].open,
                                            backgroundColor:
                                                ColorHelper.getColorGroup(
                                                    EDebatePosition.SIDE_B
                                                )![500],
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flex:
                                                results.pre[
                                                    EDebatePosition.SIDE_B
                                                ].count -
                                                results.pre[
                                                    EDebatePosition.SIDE_B
                                                ].open,
                                            backgroundColor:
                                                ColorHelper.getColorGroup(
                                                    EDebatePosition.SIDE_B
                                                )![700],
                                        }}
                                    />
                                    <Check
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            [polls?.some(
                                                (p) =>
                                                    p.type === EPollType.PRE &&
                                                    p.value === true
                                            )
                                                ? 'left'
                                                : 'right']: '1rem',
                                        }}
                                    />
                                </Box>
                                <Typography variant="h2" align="center">
                                    Before
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={{ gap: 1, width: '100%' }}
                            >
                                <Box
                                    display="flex"
                                    sx={{
                                        height: 64,
                                        backgroundColor: '#000',
                                        padding: '0.5rem',
                                        position: 'relative',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flex:
                                                results.post[
                                                    EDebatePosition.SIDE_A
                                                ].count -
                                                results.post[
                                                    EDebatePosition.SIDE_A
                                                ].changed,
                                            backgroundColor:
                                                ColorHelper.getColorGroup(
                                                    EDebatePosition.SIDE_A
                                                )![700],
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flex: results.post[
                                                EDebatePosition.SIDE_A
                                            ].changed,
                                            backgroundColor:
                                                ColorHelper.getColorGroup(
                                                    EDebatePosition.SIDE_A
                                                )![500],
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flex: results.post[
                                                EDebatePosition.SIDE_B
                                            ].changed,
                                            backgroundColor:
                                                ColorHelper.getColorGroup(
                                                    EDebatePosition.SIDE_B
                                                )![500],
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flex:
                                                results.post[
                                                    EDebatePosition.SIDE_B
                                                ].count -
                                                results.post[
                                                    EDebatePosition.SIDE_B
                                                ].changed,
                                            backgroundColor:
                                                ColorHelper.getColorGroup(
                                                    EDebatePosition.SIDE_B
                                                )![700],
                                        }}
                                    />
                                    <Check
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            [polls?.some(
                                                (p) =>
                                                    p.type === EPollType.POST &&
                                                    p.value === true
                                            )
                                                ? 'left'
                                                : 'right']: '1rem',
                                        }}
                                    />
                                </Box>
                                <Typography variant="h2" align="center">
                                    After
                                </Typography>
                            </Box>
                        </>
                    )}
                </Paper>
                <Box display="flex" alignItems="center" sx={{ gap: 4 }}>
                    <IconButton onClick={handleBackClick}>
                        <ArrowBack />
                    </IconButton>
                    <Fab color="primary" onClick={handleNextClick}>
                        <ArrowForward />
                    </Fab>
                </Box>
            </Box>
        </Box>
    );
};

export default ResultsScreen;
