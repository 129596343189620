import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import settingsTheme from '../../themes/settings';
import LogoComponent from './Logo';
import {
    AppBar,
    ButtonBase,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    useTheme,
} from '@mui/material';
import {
    CheckCircle,
    CreditCard,
    Edit,
    Explore,
    Gavel,
    Info,
    Lock,
    Mail,
    Search,
    Settings,
} from '@mui/icons-material';
import { AppDispatch } from '../../redux/store';
import { EPage } from '../../model/enum';
import { TRUE } from '../../model/constant';
import { resetDebate } from '../../redux/debate/slice';

const MenuComponent: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    // auto-hiding menu
    const [lastScrollY, setLastScrollY] = useState(0);
    const [showMenu, setShowMenu] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > 20 && currentScrollY > lastScrollY) {
                setShowMenu(false);
            } else {
                setShowMenu(true);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageClick = (page: EPage) => {
        handleClose();
        dispatch(resetDebate());
        navigate(page);
    };

    // const handleLogoutClick = () => {
    //     googleLogout();
    //     dispatch(logout());
    //     navigate(EPage.HOME);
    //     AppHelper.reload();
    // };

    // const handleLoginClick = () => {
    //     handleClose();
    //     navigate(EPage.LOGIN);
    // };

    return (
        <AppBar
            position="fixed"
            sx={{
                transition: 'transform 0.3s ease-in-out',
                transform: showMenu ? 'translateY(0)' : 'translateY(-100%)',
                height: theme.spacing(8),
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ height: '100%' }}
            >
                <ButtonBase onClick={() => handlePageClick(EPage.HOME)}>
                    <LogoComponent size="small" />
                </ButtonBase>
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                        gap: { xs: 1, sm: 1, md: 2 },
                        '@media print': { display: 'none' },
                    }}
                >
                    <IconButton
                        id="basic-button"
                        aria-controls={menuOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? TRUE : undefined}
                        onClick={handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <ThemeProvider theme={settingsTheme}>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem
                                onClick={() =>
                                    handlePageClick(EPage.ACCOUNT_DEBATES)
                                }
                            >
                                {[
                                    EPage.ACCOUNT_DEBATES,
                                    EPage.ACCOUNT_CREDITS,
                                    EPage.ACCOUNT_SSO,
                                ].includes(location.pathname as EPage) ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Your account</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => handlePageClick(EPage.CREDITS)}
                            >
                                {location.pathname === EPage.CREDITS ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <CreditCard fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Get credits</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                onClick={() => handlePageClick(EPage.HOME)}
                            >
                                {location.pathname === EPage.HOME ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Explore fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Browse debates</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => handlePageClick(EPage.CREATE)}
                            >
                                {location.pathname === EPage.CREATE ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Edit fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Start a debate</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => handlePageClick(EPage.SEARCH)}
                            >
                                {location.pathname === EPage.SEARCH ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Search fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Search debates</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                onClick={() =>
                                    handlePageClick(EPage.TERMS_OF_USE)
                                }
                            >
                                {location.pathname === EPage.TERMS_OF_USE ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Gavel fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Terms of Use</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    handlePageClick(EPage.PRIVACY_POLICY)
                                }
                            >
                                {location.pathname === EPage.PRIVACY_POLICY ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Lock fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Privacy Policy</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                onClick={() => handlePageClick(EPage.CONTACT)}
                            >
                                {location.pathname === EPage.CONTACT ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Mail fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Contact form</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => handlePageClick(EPage.ABOUT)}
                            >
                                {location.pathname === EPage.ABOUT ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Info fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>About botVbot</ListItemText>
                            </MenuItem>
                            {/* {idProvider === EIdentityProvider.GOOGLE && (
                            <MenuItem onClick={handleLogoutClick}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Sign out</ListItemText>
                            </MenuItem>
                        )}
                        {idProvider === EIdentityProvider.BOTVBOT && (
                            <MenuItem onClick={handleLoginClick}>
                                {location.pathname === EPage.LOGIN ? (
                                    <ListItemIcon>
                                        <CheckCircle
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <Login fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>
                                    {stashedIdProvider === undefined
                                        ? 'Connect to Google'
                                        : 'Sign in'}
                                </ListItemText>
                            </MenuItem>
                        )} */}
                        </Menu>
                    </ThemeProvider>
                </Box>
            </Box>
        </AppBar>
    );
};

export default MenuComponent;
