import axios from 'axios';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setStartupSeen, setWarningMessage } from '../../../redux/app/slice';
import { Controller, useForm } from 'react-hook-form';
import { EAppState, EPage } from '../../../model/enum';
import { UserHelper } from '../../../helpers/User';
import { Email } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

type ContactFormData = {
    email: string;
    firstName: string;
    message: string;
};

const ContactScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const appState = useSelector((state: RootState) => state.app.appState);
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const [busy, setBusy] = useState(false);
    const [sent, setSent] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ContactFormData>();

    const handleHomeClick = () => {
        navigate(EPage.HOME);
    };

    const onSubmit = async (data: ContactFormData) => {
        setBusy(true);
        const response = await axios.post<{ success: boolean }>(
            `${apiUrl}/contact?${UserHelper.formatAuth(userAuth)}`,
            data
        );
        setBusy(false);
        if (response?.data?.success === true) {
            setSent(true);
        } else {
            dispatch(setWarningMessage('Failed to send message'));
        }
    };

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <Box
            display="flex"
            onSubmit={handleSubmit(onSubmit)}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            {sent ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: '100%', gap: 4 }}
                >
                    <Email
                        sx={{ width: 128, height: 128, color: green.A400 }}
                    />
                    <Typography variant="h1" align="center">
                        Message sent!
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleHomeClick}
                    >
                        Browse debates
                    </Button>
                </Box>
            ) : (
                <Box
                    component="form"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        maxWidth: 960,
                        padding: { xs: '2rem', sm: '3rem' },
                        gap: 2,
                    }}
                >
                    <Typography variant="h1" align="center">
                        Contact a human
                    </Typography>
                    {appState !== EAppState.LOADED ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                width="100%"
                            >
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'Invalid email address',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Your email address"
                                            variant="outlined"
                                            margin="normal"
                                            error={!!errors.email}
                                            helperText={errors.email?.message}
                                            inputProps={{
                                                maxLength: 128,
                                            }}
                                            disabled={busy}
                                        />
                                    )}
                                />
                                <Controller
                                    name="firstName"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'First name is required',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Your first name"
                                            variant="outlined"
                                            margin="normal"
                                            error={!!errors.firstName}
                                            helperText={
                                                errors.firstName?.message
                                            }
                                            inputProps={{
                                                autoComplete: 'off',
                                                maxLength: 32,
                                            }}
                                            disabled={busy}
                                        />
                                    )}
                                />
                                <Controller
                                    name="message"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Message is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Your message"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            multiline
                                            rows={4}
                                            error={!!errors.message}
                                            helperText={errors.message?.message}
                                            inputProps={{
                                                autoComplete: 'off',
                                                maxLength: 500,
                                            }}
                                            disabled={busy}
                                        />
                                    )}
                                />
                            </Box>

                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ gap: 4, position: 'relative' }}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={busy}
                                >
                                    Submit
                                </Button>
                                {busy && (
                                    <CircularProgress
                                        sx={{
                                            position: 'absolute',
                                            right: '-4rem',
                                            top: 'auto',
                                            bottom: 'auto',
                                        }}
                                    />
                                )}
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ContactScreen;
