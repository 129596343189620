import { Box, Link, Paper, ThemeProvider, Typography } from '@mui/material';
import { USER_PARAM_C } from '../../../model/constant';
import { useEffect } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setAppState, setStartupSeen } from '../../../redux/app/slice';
import { StringHelper } from '../../../helpers/String';
import shopTheme from '../../../themes/shop';
import { EAppState, EIdentityProvider, EPage } from '../../../model/enum';
import GoogleComponent from '../../components/Google';
import { UserHelper } from '../../../helpers/User';
import { useNavigate } from 'react-router-dom';
import { loadUser } from '../../../redux/user/actions';

const LoginScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const idProvider = useSelector(
        (state: RootState) => state.user.auth?.idProvider
    );

    const handleGenericLoginClick = async () => {
        localStorage.removeItem(USER_PARAM_C);
        await dispatch(loadUser());
        dispatch(setAppState(EAppState.LOADED));
        navigate(EPage.HOME);
    };

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    useEffect(() => {
        if (idProvider === EIdentityProvider.GOOGLE) {
            navigate(EPage.CREATE);
        }
    }, [idProvider]);

    return (
        <ThemeProvider theme={shopTheme}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                    gap: 12,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        maxWidth: 960,
                        padding: { xs: '2rem', sm: '3rem' },
                        gap: 4,
                    }}
                >
                    <Typography variant="h1" align="center">
                        {UserHelper.getStashedIdProvider() !== undefined
                            ? 'Sign in'
                            : 'Connect to Google'}
                    </Typography>
                    <Paper
                        elevation={9}
                        sx={{ padding: '2rem 4rem', maxWidth: 720 }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ gap: 2 }}
                        >
                            {idProvider === EIdentityProvider.GOOGLE ? (
                                <></>
                            ) : (
                                <>
                                    <Typography variant="body2" align="center">
                                        {StringHelper.insertNonBreakingSpace(
                                            'Click the button below to link to your Google account. So you can better track your debates, votes, and credits!'
                                        )}
                                    </Typography>
                                    <GoogleComponent />
                                </>
                            )}
                        </Box>
                    </Paper>
                    <Typography variant="caption">
                        <Link
                            color="textSecondary"
                            onClick={handleGenericLoginClick}
                        >
                            Do not connect with Google
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default LoginScreen;
