import { createTheme } from '@mui/material/styles';
import pollTheme from './poll';

const summaryTheme = createTheme(pollTheme, {
    typography: {
        body1: {
            fontSize: '1.3rem',
            lineHeight: 1.3,
        },
    },
});

export default summaryTheme;
