import { createTheme } from '@mui/material/styles';
import mainTheme from './main';
import { LOGO_FONT } from '../model/constant';

const logoSmallTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontFamily: LOGO_FONT,
            fontSize: '2.4rem',
            lineHeight: 1,
        },
    },
});

export default logoSmallTheme;
