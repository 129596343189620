import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const topicsTheme = createTheme(mainTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    padding: '0.6rem 1rem',
                    textTransform: 'none',
                    [mainTheme.breakpoints.down('sm')]: {
                        padding: '0.4rem 0.6rem',
                    },
                },
            },
        },
        MuiGrid2: {
            styleOverrides: {
                root: {
                    lineHeight: 0.8,
                },
            },
        },
    },
});

export default topicsTheme;
