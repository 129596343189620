import axios from 'axios';
import { AppDispatch, RootState } from '../store';
import { StringHelper } from '../../helpers/String';
import { DEBATE_ID_PARAM } from '../../model/constant';
import {
    EDebateFormat,
    EDebateSpice,
    EDebateStatus,
    EPollType,
    EReturnCode,
} from '../../model/enum';
import { setActiveDebate, setPolls } from './slice';
import {
    setDefaultFormat,
    setDefaultSpice,
    setRecentTopic,
    setWarningMessage,
} from '../app/slice';
import { PollHelper } from '../../helpers/Poll';
import { ArrayHelper } from '../../helpers/Array';
import { UserHelper } from '../../helpers/User';
import { AppHelper } from '../../helpers/App';
import { IDebate, IPoll } from '../../model/interface';
import { DateTimeHelper } from '../../helpers/DateTime';
import { checkAge } from '../user/actions';
import { setUserInfo } from '../user/slice';

const loadDebate =
    (key: string) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<EReturnCode> => {
        // console.log('Loading debate...');
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const state = getState();
        const userAuth = state.user.auth;

        // load debate
        if (
            StringHelper.isNotEmpty(key) &&
            StringHelper.isNotEmpty(userAuth?.accessToken) &&
            StringHelper.isNotEmpty(userAuth?.passwordToken)
        ) {
            const response = await axios.get<{ debate: IDebate }>(
                `${apiUrl}/debate?${DEBATE_ID_PARAM}=${key}&${UserHelper.formatAuth(userAuth!)}`
            );
            if (response?.data?.debate?.key) {
                const debate = response.data.debate;
                if (!(await dispatch(checkAge(debate.spice)))) {
                    return EReturnCode.REDIRECT_TO_AGE_GATE;
                }
                const polls = await PollHelper.loadPolls(
                    apiUrl!,
                    debate,
                    userAuth!
                );
                dispatch(setActiveDebate(debate));
                dispatch(setDefaultSpice(debate.spice));
                dispatch(setDefaultFormat(debate.format));
                dispatch(setPolls(polls));
                document.title = `botVbot: “${debate.topic.issue}” ${AppHelper.getDebateSpiceName(debate.spice)} debate`;
                return EReturnCode.SUCCESS;
            } else {
                throw new Error('Invalid debate key');
            }
        }
        return EReturnCode.SUCCESS;
    };

const getIsAgeAppropriate =
    (spice: EDebateSpice) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const state = getState();
        if (spice !== EDebateSpice.HOT) {
            return true;
        }
        return DateTimeHelper.isOldEnough(state.user.info?.dob);
    };

const createDebate =
    (topic: string, spice: EDebateSpice, format: EDebateFormat) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const state = getState();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const response = await axios.post<{ debate?: IDebate; error?: string }>(
            `${apiUrl}/debate?${UserHelper.formatAuth(state.user.auth!)}`,
            {
                topic,
                spice,
                format,
            }
        );
        if (response?.data?.debate?.key) {
            const newDebate: IDebate = response.data.debate;
            dispatch(setActiveDebate(newDebate));
            dispatch(setRecentTopic(undefined));
            return true;
        } else if (response.data?.error) {
            dispatch(setWarningMessage(response.data.error));
        }
        return false;
    };

const savePollResponse =
    (type: EPollType, value: boolean) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        const debate = state.debate.active;

        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const response = await axios.put<{ polls: IPoll[] }>(
            `${apiUrl}/poll?${DEBATE_ID_PARAM}=${debate!.key}&${UserHelper.formatAuth(state.user.auth!)}`,
            { type, value }
        );
        if (ArrayHelper.isNotEmpty(response?.data?.polls)) {
            dispatch(setPolls(PollHelper.normalizeData(response?.data?.polls)));

            if (debate!.status === EDebateStatus.CREATING) {
                // publish debate
                const response = await axios.post<{ debate: IDebate }>(
                    `${apiUrl}/discussion?${DEBATE_ID_PARAM}=${debate!.key}&${UserHelper.formatAuth(state.user.auth!)}`
                );
                if (response?.data?.debate) {
                    const updatedDebate: IDebate = response.data.debate;
                    dispatch(setActiveDebate(updatedDebate));
                }
            }
        }
    };

const promptForAge =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(setUserInfo({ ...state.user.info!, dob: null }));
    };

const cancelAgePrompt =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(setUserInfo({ ...state.user.info!, dob: undefined }));
    };

const deleteDebate =
    (key: string) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const state = getState();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
        const response = await axios.delete<{
            success: boolean;
            error?: string;
        }>(
            `${apiUrl}/debate?key=${key}&${UserHelper.formatAuth(state.user.auth!)}`
        );
        if (response?.data?.success === true) {
            // reload debates
            return true;
        } else if (response.data?.error) {
            dispatch(setWarningMessage(response.data.error));
        }
        return false;
    };

export {
    loadDebate,
    createDebate,
    savePollResponse,
    getIsAgeAppropriate,
    promptForAge,
    cancelAgePrompt,
    deleteDebate,
};
