import { Theme } from '@mui/material';

export const SMALL_GAPS = { xs: 1, sm: 2, md: 4 };
export const MEDIUM_GAPS = { xs: 2, sm: 3, md: 4 };
export const LARGE_GAPS = { xs: 2, sm: 3, md: 4 };
export const HUGE_GAPS = { xs: 3, sm: 5, md: 8 };

export const ICON_SIZE_SMALL = {
    width: { xs: 32, sm: 40, md: 48 },
    height: { xs: 32, sm: 40, md: 48 },
};

export const ICON_SIZE_MEDIUM = {
    width: { xs: 48, sm: 56, md: 64 },
    height: { xs: 48, sm: 56, md: 64 },
};

export const ICON_SIZE_LARGE = {
    width: { xs: 64, sm: 80, md: 96 },
    height: { xs: 64, sm: 80, md: 96 },
};
