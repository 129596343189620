import axios from 'axios';
import { Box, Button, Paper, ThemeProvider, Typography } from '@mui/material';
import { TRUE } from '../../../model/constant';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAppState,
    setErrorMessage,
    setStartupSeen,
    setSuccessMessage,
    setWarningMessage,
} from '../../../redux/app/slice';
import { StringHelper } from '../../../helpers/String';
import shopTheme from '../../../themes/shop';
import { AppHelper } from '../../../helpers/App';
import {
    EAppState,
    ECreditType,
    EIdentityProvider,
    EPage,
    EReturnCode,
} from '../../../model/enum';
import { useNavigate, useSearchParams } from 'react-router-dom';
import GoogleComponent from '../../components/Google';
import PurchaseComponent from '../../components/shop/Purchase';
import { CheckCircle } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { loadUser } from '../../../redux/user/actions';
import { UserHelper } from '../../../helpers/User';

interface IProps {
    purchaseComplete?: boolean;
}

interface IConfirmationResponse {
    couponId?: string;
    orderStatus?: ECreditType;
}

const CreditScreen: React.FC<IProps> = ({ purchaseComplete }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const idProvider = useSelector(
        (state: RootState) => state.user.auth?.idProvider
    );
    const recentTopic = useSelector(
        (state: RootState) => state.app.recentTopic
    );
    const [searchParams] = useSearchParams();
    const apiUrl = `${process.env.REACT_APP_API_URL}/shop`;
    const gift = searchParams.get('gift') === TRUE;
    const paymentIntent = searchParams.get('payment_intent') || undefined;
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const [orderStatus, setOrderStatus] = useState<ECreditType | undefined>();
    const [couponId, setCouponId] = useState<string | undefined>();

    const renderCosts = (costs: string[]) => {
        return costs.reduce(
            (acc, cost, index) => {
                if (index === 0) {
                    return [cost];
                } else {
                    return [...acc, <br key={index} />, cost];
                }
            },
            [] as (string | JSX.Element)[]
        );
    };

    const handleCreate = async () => {
        dispatch(setAppState(EAppState.LOADING));
        try {
            const returnCode = await dispatch(loadUser());
            dispatch(setAppState(EAppState.LOADED));
            if (returnCode === EReturnCode.SUCCESS) {
                navigate(EPage.CREATE);
            }
        } catch (error) {
            //
        }
    };

    const handleCopyGift = () => {
        if (
            AppHelper.copyToClipboard(
                AppHelper.makeSiteLink(EPage.COUPON, couponId!)
            )
        ) {
            dispatch(setSuccessMessage('Copied to your clipboard'));
        } else {
            dispatch(
                setWarningMessage(
                    'That didn’t work – please copy the reference manually'
                )
            );
        }
    };

    const fetchConfirmationLink = async () => {
        try {
            const response = await axios.get<IConfirmationResponse>(
                `${apiUrl}/confirmation?${UserHelper.formatAuth(userAuth)}&paymentIntent=${paymentIntent}`
            );
            const confirmationResponse: IConfirmationResponse | undefined =
                response?.data;
            if (confirmationResponse !== undefined) {
                setCouponId(confirmationResponse.couponId);
                setOrderStatus(confirmationResponse.orderStatus);
            }
        } catch (err) {
            setErrorMessage('Failed to get order confirmation');
        }
    };

    useEffect(() => {
        if (StringHelper.isNotEmpty(paymentIntent)) {
            fetchConfirmationLink();
        }
    }, [paymentIntent]);

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <>
            {purchaseComplete === true ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: '100%' }}
                >
                    <CheckCircle
                        sx={{ width: 128, height: 128, color: green.A400 }}
                    />
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ maxWidth: 800, gap: 4 }}
                    >
                        {gift ? (
                            <>
                                <Typography variant="h1" align="center">
                                    Thank you for your support!
                                </Typography>
                                {!orderStatus ||
                                orderStatus === ECreditType.PENDING ||
                                StringHelper.isEmpty(couponId) ? (
                                    <>
                                        <Typography
                                            variant="body1"
                                            align="center"
                                        >
                                            Your gift link will be ready
                                            shortly.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => AppHelper.reload()}
                                        >
                                            Reload to check again
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            variant="body1"
                                            align="center"
                                        >
                                            Click the button below to copy a
                                            link to your clipboard, which you
                                            can then share with the recipient.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleCopyGift}
                                        >
                                            Copy gift link
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Typography variant="body1" align="center">
                                    Thank you for your support!
                                </Typography>
                                {!orderStatus ||
                                orderStatus === ECreditType.PENDING ? (
                                    <>
                                        <Typography
                                            variant="body1"
                                            align="center"
                                        >
                                            Your credits will be added to your
                                            account shortly.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => AppHelper.reload()}
                                        >
                                            Reload to check again
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCreate}
                                    >
                                        {StringHelper.isNotEmpty(recentTopic)
                                            ? 'Continue with your topic'
                                            : 'Start a debate'}
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            ) : (
                <ThemeProvider theme={shopTheme}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="flex-start"
                        sx={{
                            paddingTop: 4,
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{
                                maxWidth: 960,
                                gap: 4,
                                paddingBottom: 4,
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={{ gap: 3, maxWidth: 640 }}
                            >
                                {StringHelper.isNotEmpty(recentTopic) ? (
                                    <>
                                        <Typography variant="h1" align="center">
                                            Your debate about
                                            <br />
                                            &ldquo;
                                            {AppHelper.truncateText(
                                                recentTopic!,
                                                24
                                            )}
                                            &rdquo;
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            align="center"
                                        >
                                            {StringHelper.insertNonBreakingSpace(
                                                'Please support this project by buying some credits.'
                                            )}
                                        </Typography>{' '}
                                    </>
                                ) : (
                                    <Typography variant="h1" align="center">
                                        {StringHelper.insertNonBreakingSpace(
                                            'Spark the ultimate showdowns!'
                                        )}
                                    </Typography>
                                )}
                                <Typography variant="body2" align="center">
                                    {renderCosts(AppHelper.getCosts())}
                                </Typography>
                            </Box>
                            {idProvider === EIdentityProvider.GOOGLE ? (
                                <PurchaseComponent size="full" />
                            ) : (
                                <>
                                    <PurchaseComponent size="minimal" />
                                    <Paper elevation={4} sx={{ maxWidth: 720 }}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            sx={{ gap: 2 }}
                                        >
                                            <Typography
                                                variant="body1"
                                                align="center"
                                            >
                                                {StringHelper.insertNonBreakingSpace(
                                                    'But first, create a proper account so you can log in from anywhere and keep track of your votes and debates.'
                                                )}
                                            </Typography>
                                            <GoogleComponent />
                                        </Box>
                                    </Paper>
                                    <Typography variant="body1" align="center">
                                        {StringHelper.insertNonBreakingSpace(
                                            'Once you’ve linked your account, you’ll be able to buy credits.'
                                        )}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                </ThemeProvider>
            )}
        </>
    );
};

export default CreditScreen;
