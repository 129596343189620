import React, { useEffect } from 'react';
import { googleLogout } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store';
import { EIdentityProvider, EPage } from '../../../model/enum';
import { StringHelper } from '../../../helpers/String';
import GoogleComponent from '../../components/Google';
import { logout } from '../../../redux/user/actions';
import { useNavigate } from 'react-router-dom';
import { AppHelper } from '../../../helpers/App';

const SSOScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const userInfo = useSelector((state: RootState) => state.user.info);
    const navigate = useNavigate();
    const idProvider = useSelector(
        (state: RootState) => state.user.auth?.idProvider
    );

    const handleLogoutClick = () => {
        googleLogout();
        dispatch(logout());
        navigate(EPage.HOME);
        AppHelper.reload();
    };

    useEffect(() => {
        // if (idProvider === EIdentityProvider.GOOGLE) {
        //     navigate(EPage.CREATE);
        // }
    }, [idProvider]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            sx={{
                paddingTop: { xs: '1rem', sm: '2rem' },
                paddingBottom: { xs: '1rem', sm: '2rem' },
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ width: '100%', maxWidth: 720, gap: 4 }}
            >
                {idProvider === EIdentityProvider.GOOGLE ? (
                    <>
                        <Typography variant="h1" align="center">
                            Connection to Google
                        </Typography>
                        <Typography variant="body1" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                `${userInfo?.name || 'Hey'}, your botVbot account is connected to your Google account, so you can log in from anywhere, and start debates of your own.`
                            )}
                        </Typography>
                        {userInfo !== undefined && (
                            <Typography variant="caption" align="center">
                                {userInfo.email}
                            </Typography>
                        )}
                        <Button variant="outlined" onClick={handleLogoutClick}>
                            Sign out
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography variant="body1" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                'You can connect your botVbot to your Google account, so that you can start debates of your own, and log in from anywhere.'
                            )}
                        </Typography>
                        <GoogleComponent />
                    </>
                )}
            </Box>
        </Box>
    );
};

export default SSOScreen;
