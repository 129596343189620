import React from 'react';
import { Box, ButtonBase, Menu, MenuItem, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { AppHelper } from '../../../helpers/App';
import { ColorHelper } from '../../../helpers/Color';
import { EDebateFormat, EDebateSpice } from '../../../model/enum';
import { EnvironmentHelper } from '../../../helpers/Environment';
import { TRUE } from '../../../model/constant';

interface IProps {
    action: string;
    selectedSpice: EDebateSpice;
    selectedFormat: EDebateFormat;
    onSelectSpice: (s: EDebateSpice) => void;
    onSelectFormat: (f: EDebateFormat) => void;
}

const SelectorComponent: React.FC<IProps> = ({
    action,
    selectedSpice,
    selectedFormat,
    onSelectSpice,
    onSelectFormat,
}) => {
    const validSpices = [EDebateSpice.MILD, EDebateSpice.MEDIUM];
    if (EnvironmentHelper.parseAsBoolean(process.env.REACT_APP_SHOCKING)) {
        validSpices.push(EDebateSpice.HOT);
    }
    const formats = [
        EDebateFormat.STANDARD,
        EDebateFormat.MEDIUM,
        // EDebateFormat.LONG,
    ];

    if (process.env.NODE_ENV === 'development') {
        formats.unshift(EDebateFormat.TEST);
    }

    const [anchorElSpice, setAnchorElSpice] =
        React.useState<null | HTMLElement>(null);
    const [anchorElFormat, setAnchorElFormat] =
        React.useState<null | HTMLElement>(null);

    const showSpiceMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElSpice(event.currentTarget);
    };

    const showFormatMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElFormat(event.currentTarget);
    };

    const handleSelectSpice = (s: EDebateSpice) => {
        handleMenuClose();
        onSelectSpice(s);
    };

    const handleSelectFormat = (f: EDebateFormat) => {
        handleMenuClose();
        onSelectFormat(f);
    };

    const handleMenuClose = () => {
        setAnchorElSpice(null);
        setAnchorElFormat(null);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            sx={{ gap: 6, width: '100%' }}
        >
            <Typography variant="h1" align="center">
                {`${action} a `}
                <ButtonBase
                    aria-controls={anchorElSpice ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorElSpice ? TRUE : undefined}
                    onClick={showSpiceMenu}
                    sx={{
                        verticalAlign: 'baseline',
                        borderBottomWidth: { xs: 2, sm: 2, md: 3 },
                        borderBottomStyle: 'dotted',
                        borderBottomColor:
                            ColorHelper.getSpiceColor(selectedSpice),
                    }}
                >
                    <Typography
                        variant="h1"
                        component="span"
                        sx={{
                            color: ColorHelper.getSpiceColor(selectedSpice),
                        }}
                    >
                        {AppHelper.getDebateSpiceName(selectedSpice)}
                    </Typography>
                </ButtonBase>{' '}
                <ButtonBase
                    aria-controls={anchorElFormat ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorElFormat ? TRUE : undefined}
                    onClick={showFormatMenu}
                    sx={{
                        verticalAlign: 'baseline',
                        borderBottomWidth: { xs: 2, sm: 2, md: 3 },
                        borderBottomStyle: 'dotted',
                        borderBottomColor: grey[500],
                    }}
                >
                    <Typography variant="h1" component="span">
                        {AppHelper.getDebateFormatName(selectedFormat)}
                    </Typography>
                </ButtonBase>{' '}
                debate!
            </Typography>
            <Menu
                id="spice-menu"
                anchorEl={anchorElSpice}
                open={!!anchorElSpice}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {validSpices.map((s, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleSelectSpice(s)}
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Typography
                            component="span"
                            variant="h1"
                            sx={{
                                color: ColorHelper.getSpiceColor(s),
                            }}
                        >
                            {AppHelper.getDebateSpiceName(s)}
                        </Typography>
                        <Typography component="span" variant="caption">
                            {AppHelper.getDebateSpiceDescription(s)}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
            <Menu
                id="format-menu"
                anchorEl={anchorElFormat}
                open={!!anchorElFormat}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {formats.map((f, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleSelectFormat(f)}
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Typography component="span" variant="h1">
                            {AppHelper.getDebateFormatName(f)}
                        </Typography>
                        <Typography component="span" variant="caption">
                            {`${AppHelper.getCreditCost(f)} credit${AppHelper.getCreditCost(f) > 1 ? 's' : ''} - ${AppHelper.getDebateFormatDescription(f)}`}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default SelectorComponent;
