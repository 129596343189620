import { createTheme } from '@mui/material/styles';
import mainTheme from './main';
import { LOGO_FONT } from '../model/constant';

const logoLargeTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontFamily: LOGO_FONT,
            fontSize: '6rem',
            lineHeight: 0.8,
        },
    },
});

export default logoLargeTheme;
