import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const settingsTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontSize: '1.4rem',
        },
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    gap: '0.5rem',
                    padding: '0.8rem 1rem',
                    [mainTheme.breakpoints.down('sm')]: {
                        gap: '0.2rem',
                    },
                },
            },
        },
    },
});

export default settingsTheme;
