import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import App from './view/App';
import './index.css';
import mainTheme from './themes/main';
import { ROOT_ELEMENT_ID } from './model/constant';
import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById(ROOT_ELEMENT_ID);
const root = createRoot(container!); // Create a root.

console.log('Version ' + process.env.REACT_APP_VERSION);

root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY!}>
        <Provider store={store}>
            <ThemeProvider theme={mainTheme}>
                <CssBaseline />
                <BrowserRouter
                    future={{
                        v7_startTransition: true,
                        v7_relativeSplatPath: true,
                    }}
                >
                    <App />
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </GoogleOAuthProvider>
);
