import {
    Box,
    Button,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import { StringHelper } from '../../../helpers/String';
import { useEffect } from 'react';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { setStartupSeen } from '../../../redux/app/slice';
import { useNavigate } from 'react-router-dom';
import { EPage } from '../../../model/enum';
import aboutTheme from '../../../themes/about';

const AboutScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleContactClick = () => {
        navigate(EPage.CONTACT);
    };

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <ThemeProvider theme={aboutTheme}>
            <Box display="flex" sx={{ paddingTop: '3rem' }}>
                {/* <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ gap: 2 }}
                >
                    <Typography variant="h1" align="center">
                        &ldquo;
                        {StringHelper.insertNonBreakingSpace(
                            ArrayHelper.shuffle(FAKE_PROVERBS)[0]
                        )}
                        &rdquo;
                    </Typography>
                    <Typography variant="caption">
                        – A Large Language Model
                    </Typography>
                </Box> */}
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        gap: 4,
                        maxWidth: 960,
                        margin: 'auto',
                        paddingBottom: '3rem',
                    }}
                >
                    <Typography variant="h1" align="center">
                        {StringHelper.insertNonBreakingSpace(
                            'This is an experiment'
                        )}
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ gap: 4, maxWidth: 630, margin: 'auto' }}
                    >
                        <Typography variant="body1" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                'One source of inspiration is the YouTube video from BigThink, “Psychologist debunks 8 myths of mass scale.” Another is post-truth politics  – do we live in an age of persuasion?'
                            )}
                        </Typography>

                        <Typography variant="body2" align="center">
                            {StringHelper.insertNonBreakingSpace(
                                'My hope is that this site will entertain; demonstrate the capabilities of AI (including its buffoonery); and, if not make us more tolerant of one another’s divergent views, at least make us more aware of the persuasive techniques and cognitive biases that tend to shape them.'
                            )}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button variant="outlined" onClick={handleContactClick}>
                            Contact a human
                        </Button>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default AboutScreen;
