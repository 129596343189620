import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const aboutTheme = createTheme(mainTheme, {
    typography: {
        h1: {
            fontSize: '3rem',
        },
        body1: {
            fontSize: '1.4rem',
        },
        body2: {
            fontSize: '1.2rem',
        },
    },
});

export default aboutTheme;
