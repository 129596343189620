import axios from 'axios';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    IconButton,
    Link,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import React, { useEffect, useState } from 'react';
import { DEBATE_ID_PARAM } from '../../../model/constant';
import { EDebatePosition, EDebateStage } from '../../../model/enum';
import { ArrayHelper } from '../../../helpers/Array';
import { UserHelper } from '../../../helpers/User';
import { IDebate } from '../../../model/interface';
import { setActiveDebate, setDebateStage } from '../../../redux/debate/slice';
import ParticipantComponent from '../../components/debate/Participant';
import BusyComponent from '../../components/Busy';
import dialogTheme from '../../../themes/dialog';
import { SMALL_GAPS } from '../../../styles';

const SummaryScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const debate = useSelector((state: RootState) => state.debate.active);
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const [busy, setBusy] = React.useState<boolean>(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [linkToOpen, setLinkToOpen] = useState<string | null>(null);

    const reloadDebate = async () => {
        const response = await axios.get<{ debate: IDebate }>(
            `${apiUrl}/debate?${DEBATE_ID_PARAM}=${debate!.key}&${UserHelper.formatAuth(userAuth!)}`
        );
        if (response?.data?.debate?.key) {
            const savedDebate: IDebate = response.data.debate;
            dispatch(setActiveDebate(savedDebate));
        }
        setBusy(false);
    };

    const fetchSummaries = async () => {
        try {
            const response = await axios.put<{ success: boolean }>(
                `${apiUrl}/debate/summary?${DEBATE_ID_PARAM}=${encodeURIComponent(debate!.key)}&${UserHelper.formatAuth(userAuth!)}`
            );
            if (response?.data?.success === true) {
                reloadDebate();
            }
        } catch (err) {
            setBusy(false);
        }
    };

    const handleNextClick = async () => {
        dispatch(setDebateStage(EDebateStage.EXIT_POLL));
    };

    const handleBackClick = async () => {
        dispatch(setDebateStage(EDebateStage.DISCUSSION));
    };

    const handleLinkClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        href: string
    ) => {
        event.preventDefault();
        setLinkToOpen(href);
        setConfirmOpen(true);
    };

    const handleDialogClose = () => {
        setConfirmOpen(false);
        setLinkToOpen(null);
    };

    const handleProceed = () => {
        if (linkToOpen) {
            window.open(linkToOpen, '_blank');
        }
        handleDialogClose();
    };

    useEffect(() => {
        if (userAuth !== undefined && debate !== undefined) {
            let needSummaries = false;
            Object.keys(debate.participants).forEach((position) => {
                if (
                    position !== EDebatePosition.MODERATOR &&
                    ArrayHelper.isEmpty(
                        debate.participants[position].summary?.points
                    )
                ) {
                    needSummaries = true;
                }
            });
            if (needSummaries) {
                fetchSummaries();
            } else {
                setBusy(false);
            }
        }
    }, [debate]);

    return busy ? (
        <BusyComponent />
    ) : (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent={{
                    xs: 'flex-start',
                    sm: 'flex-start',
                    md: 'center',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ gap: 4, maxWidth: 1200, padding: '3rem 0' }}
                >
                    <Typography variant="h1" align="center">
                        Summary
                    </Typography>
                    <Box display="flex" flexDirection="column" sx={{ gap: 3 }}>
                        <Box display="flex" sx={{ gap: SMALL_GAPS }}>
                            {[
                                EDebatePosition.SIDE_A,
                                EDebatePosition.SIDE_B,
                            ].map((position) => (
                                <Box
                                    key={position}
                                    sx={{ height: '100%', flex: 1 }}
                                >
                                    <ParticipantComponent
                                        speaker={
                                            debate!.participants[position]!
                                        }
                                        debateTopic={debate!.topic}
                                    />
                                </Box>
                            ))}
                        </Box>
                        <Box display="flex" sx={{ gap: SMALL_GAPS }}>
                            {[
                                EDebatePosition.SIDE_A,
                                EDebatePosition.SIDE_B,
                            ].map((position) => (
                                <Box
                                    key={position}
                                    display="flex"
                                    flexDirection="column"
                                    sx={{
                                        flex: 1,
                                        gap: 1,
                                        marginLeft: { xs: 1, sm: 1, md: 5 },
                                    }}
                                >
                                    <Typography variant="h2">
                                        Main points
                                    </Typography>
                                    <Box
                                        component="ul"
                                        display="flex"
                                        flexDirection="column"
                                        sx={{ gap: 1 }}
                                    >
                                        {debate?.participants[
                                            position
                                        ].summary?.points.map(
                                            (point, index) => (
                                                <Typography
                                                    component="li"
                                                    variant="body1"
                                                    key={index}
                                                    sx={{
                                                        marginLeft: {
                                                            xs: 2,
                                                            sm: 2,
                                                            md: 4,
                                                        },
                                                    }}
                                                >
                                                    {point}
                                                </Typography>
                                            )
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        {ArrayHelper.isNotEmpty(
                            debate?.participants[EDebatePosition.SIDE_A].summary
                                ?.links
                        ) && (
                            <Box display="flex" sx={{ gap: SMALL_GAPS }}>
                                {[
                                    EDebatePosition.SIDE_A,
                                    EDebatePosition.SIDE_B,
                                ].map((position) => (
                                    <Box
                                        key={position}
                                        display="flex"
                                        flexDirection="column"
                                        sx={{ flex: 1, gap: 1 }}
                                    >
                                        <Typography variant="h2">
                                            References{' '}
                                            <Typography
                                                variant="h2"
                                                component="span"
                                                sx={{
                                                    textTransform: 'none',
                                                    opacity: 0.54,
                                                }}
                                            >
                                                {' '}
                                                – May not be accurate
                                            </Typography>
                                        </Typography>
                                        <Box
                                            component="ul"
                                            display="flex"
                                            flexDirection="column"
                                            sx={{ gap: 1 }}
                                        >
                                            {debate?.participants[
                                                position
                                            ].summary?.links.map(
                                                (link, index) => (
                                                    <Typography
                                                        component="li"
                                                        variant="body1"
                                                        key={index}
                                                        sx={{
                                                            marginLeft: {
                                                                xs: 2,
                                                                sm: 2,
                                                                md: 4,
                                                            },
                                                        }}
                                                    >
                                                        <Link
                                                            href={link.url}
                                                            target="_blank"
                                                            sx={{
                                                                whiteSpace:
                                                                    'normal',
                                                            }}
                                                            onClick={(event) =>
                                                                handleLinkClick(
                                                                    event,
                                                                    link.url
                                                                )
                                                            }
                                                        >
                                                            {link.title}
                                                        </Link>
                                                    </Typography>
                                                )
                                            )}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ gap: 4 }}>
                        <IconButton onClick={handleBackClick}>
                            <ArrowBack />
                        </IconButton>
                        <Fab color="primary" onClick={handleNextClick}>
                            <ArrowForward />
                        </Fab>
                    </Box>
                </Box>
            </Box>
            <ThemeProvider theme={dialogTheme}>
                <Dialog
                    open={confirmOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" variant="h1">
                        External Link
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <DialogContentText
                            id="alert-dialog-description"
                            variant="body1"
                            color="warning"
                        >
                            {linkToOpen}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            You are about to open a link to an external website.
                            Do you want to proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleDialogClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleProceed}
                            autoFocus
                        >
                            Proceed
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </>
    );
};

export default SummaryScreen;
