import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDebate, IPoll } from '../../model/interface';
import { EDebateStage } from '../../model/enum';

interface IDebateState {
    stage?: EDebateStage;
    active?: IDebate;
    polls?: IPoll[];
}

const initialState: IDebateState = {};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setDebateStage(state, action: PayloadAction<EDebateStage>) {
            state.stage = action.payload;
        },
        setActiveDebate(state, action: PayloadAction<IDebate | undefined>) {
            state.active = action.payload;
        },
        setPolls(state, action: PayloadAction<IPoll[]>) {
            state.polls = action.payload;
        },
        resetDebate(state) {
            state.stage = undefined;
            state.active = undefined;
            state.polls = undefined;
        },
    },
});

export const { setDebateStage, setActiveDebate, setPolls, resetDebate } =
    appSlice.actions;
export default appSlice.reducer;
