import axios from 'axios';
import { AppDispatch, RootState } from '../store';
import { USER_PARAM_A, USER_PARAM_B, USER_PARAM_C } from '../../model/constant';
import { StringHelper } from '../../helpers/String';
import { setAuth, setCredits, setUserInfo } from './slice';
import { setAppState, setWarningMessage } from '../app/slice';
import { IUser, IUserAuth, IUserLoadResponse } from '../../model/interface';
import { UserHelper } from '../../helpers/User';
import {
    EAppState,
    EDebateSpice,
    EIdentityProvider,
    EReturnCode,
} from '../../model/enum';
import { getIsAgeAppropriate, promptForAge } from '../debate/actions';

const loadUser =
    () =>
    async (dispatch: AppDispatch): Promise<EReturnCode> => {
        // console.log('loadUser');
        const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

        // load user
        // check local storage for user key
        let userKeyA: string | null = localStorage.getItem(USER_PARAM_A);
        let userKeyB: string | null = localStorage.getItem(USER_PARAM_B);
        let userKeyC: string | null = localStorage.getItem(USER_PARAM_C);
        // console.log('local storage', userKeyA, userKeyB, userKeyC);
        if (StringHelper.isEmpty(userKeyA) || StringHelper.isEmpty(userKeyB)) {
            if (userKeyC === EIdentityProvider.GOOGLE) {
                return EReturnCode.REDIRECT_TO_SSO;
            } else {
                // console.log('No stored credentials found');
                // get user ID from API
                const response = await axios.post<{ auth: IUserAuth }>(
                    `${apiUrl}/user`
                );
                if (response?.data?.auth !== undefined) {
                    const userAuth: IUserAuth = response?.data?.auth;
                    // console.log('Got new user auth', userAuth);
                    if (userAuth.accessToken && userAuth.passwordToken) {
                        // save user key to local storage
                        localStorage.setItem(
                            USER_PARAM_A,
                            userAuth.accessToken
                        );
                        localStorage.setItem(
                            USER_PARAM_B,
                            userAuth.passwordToken
                        );
                        userKeyA = localStorage.getItem(USER_PARAM_A);
                        userKeyB = localStorage.getItem(USER_PARAM_B);
                        // console.log('Saved user auth');
                    }
                }
            }
        }

        if (
            StringHelper.isNotEmpty(userKeyA) &&
            StringHelper.isNotEmpty(userKeyB)
        ) {
            // console.log('Validating user...');
            // validate user key
            const response = await axios.get<IUserLoadResponse>(
                `${apiUrl}/user?${UserHelper.formatAuth({ accessToken: userKeyA!, passwordToken: userKeyB! })}`
            );
            // console.log('response', response);
            const userResponse: IUserLoadResponse | undefined = response?.data;
            await dispatch(processLoggedInUser(userResponse));
        } else {
            throw new Error('Missing user key');
        }
        return EReturnCode.SUCCESS;
    };

const updateDOB =
    (dob: number) =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        // console.log('updateUser');
        const state = getState();
        const userAuth = state.user.auth;
        if (userAuth !== undefined) {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

            const response = await axios.put<{ success: boolean }>(
                `${apiUrl}/user?${UserHelper.formatAuth(userAuth)}`,
                { dob }
            );
            if (response?.data?.success === true) {
                dispatch(setUserInfo({ ...state.user.info!, dob }));
                return;
            }
        }
        throw new Error('Failed to save date of birth');
    };

const checkAge =
    (spice: EDebateSpice) =>
    async (
        dispatch: AppDispatch,
        getState: () => RootState
    ): Promise<boolean> => {
        const state = getState();
        const isAppropriateAge = await dispatch(getIsAgeAppropriate(spice));
        if (!isAppropriateAge) {
            if (state.user.info?.dob === undefined) {
                await dispatch(promptForAge());
            } else {
                dispatch(setWarningMessage('Sorry, you’re not old enough'));
            }
            return false;
        }
        return true;
    };

const logout =
    () =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        localStorage.removeItem(USER_PARAM_A);
        localStorage.removeItem(USER_PARAM_B);
    };

const processLoggedInUser =
    (userResponse: IUserLoadResponse | undefined) =>
    async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        // console.log('processLoggedInUser', userResponse);
        if (
            userResponse?.auth !== undefined &&
            userResponse?.user !== undefined
        ) {
            // console.log('Valid user');
            dispatch(setCredits(userResponse.credits || 0));

            const userInfo: IUser = userResponse.user;
            dispatch(setUserInfo(userInfo));

            const userAuth: IUserAuth = userResponse.auth;
            dispatch(setAuth(userAuth));
            UserHelper.stashIdProvider(userAuth.idProvider);

            // update password token
            localStorage.setItem(USER_PARAM_A, userAuth.accessToken);
            localStorage.setItem(USER_PARAM_B, userAuth.passwordToken);
            dispatch(setAppState(EAppState.LOGGED_IN));
        } else {
            dispatch(logout());
            throw new Error('Invalid user key');
        }
    };

export { loadUser, logout, processLoggedInUser, updateDOB, checkAge };
