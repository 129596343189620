import axios from 'axios';
import { DEBATE_ID_PARAM } from '../model/constant';
import { IDebate, IPoll, IUserAuth } from '../model/interface';
import { ArrayHelper } from './Array';
import { UserHelper } from './User';
import { EPollType } from '../model/enum';

export class PollHelper {
    public static loadPolls = async (
        apiUrl: string,
        debate: IDebate,
        userAuth: IUserAuth
    ): Promise<IPoll[]> => {
        const response = await axios.get<{ polls: IPoll[] }>(
            `${apiUrl}/polls?${DEBATE_ID_PARAM}=${debate.key}&${UserHelper.formatAuth(userAuth)}`
        );
        if (ArrayHelper.isNotEmpty(response?.data?.polls)) {
            return this.normalizeData(response?.data?.polls);
        }
        return [];
    };

    public static normalizeData = (pollData: IPoll[]): IPoll[] => {
        return pollData.map((p: any) => {
            if (p.value === 0 || p.value === '0') {
                p.value = false;
            } else if (p.value === 1 || p.value === '1') {
                p.value = true;
            }
            return p;
        }) as IPoll[];
    };

    public static getValue = (
        polls: IPoll[] | undefined,
        type: EPollType
    ): boolean | undefined => {
        return polls?.find((p) => p.type === type)?.value;
    };
}
