import React, { useEffect } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    EAppState,
    EDebateSpice,
    EDebateStage,
    EPage,
    EPollType,
    EReturnCode,
} from '../../../model/enum';
import { ThemeProvider } from '@mui/material';
import PollScreen from './Poll';
import pollTheme from '../../../themes/poll';
import DiscussionScreen from './Discussion';
import SummaryScreen from './Summary';
import ResultsScreen from './Results';
import StartupScreen from '../Startup';
import { loadDebate, promptForAge } from '../../../redux/debate/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { StringHelper } from '../../../helpers/String';
import { setDebateStage } from '../../../redux/debate/slice';
import ShareScreen from './Share';
import { ArrayHelper } from '../../../helpers/Array';
import { setDefaultSpice } from '../../../redux/app/slice';
import summaryTheme from '../../../themes/summary';
import BusyComponent from '../../components/Busy';

const DebateContainer: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const appState = useSelector((state: RootState) => state.app.appState);
    const debateStage = useSelector((state: RootState) => state.debate.stage);
    const polls = useSelector((state: RootState) => state.debate.polls);
    const dob = useSelector((state: RootState) => state.user.info?.dob);
    const startupSeen = useSelector(
        (state: RootState) => state.app.startupSeen
    );
    const debateKey = useParams<{ key: string }>().key;
    const [busy, setBusy] = React.useState<boolean>(true);

    useEffect(() => {
        const fetchDebate = async () => {
            if (
                appState === EAppState.LOADED &&
                StringHelper.isNotEmpty(debateKey)
            ) {
                try {
                    dispatch(setDebateStage(EDebateStage.INTRO_POLL));
                    const returnCode = await dispatch(loadDebate(debateKey!));
                    setBusy(false);
                    if (returnCode === EReturnCode.REDIRECT_TO_AGE_GATE) {
                        if (dob === undefined) {
                            await dispatch(promptForAge());
                        } else {
                            dispatch(setDefaultSpice(EDebateSpice.MEDIUM));
                            navigate(EPage.HOME);
                            return;
                        }
                    }
                } catch (error) {
                    navigate(EPage.HOME);
                }
            } else {
                navigate(EPage.HOME);
            }
        };

        fetchDebate();
    }, [appState, debateKey]);

    useEffect(() => {
        if (ArrayHelper.isNotEmpty(polls)) {
            if (polls?.some((p) => p.type === EPollType.POST)) {
                dispatch(setDebateStage(EDebateStage.RESULTS));
            } else if (polls?.some((p) => p.type === EPollType.OPENNESS)) {
                dispatch(setDebateStage(EDebateStage.DISCUSSION));
            } else if (polls?.some((p) => p.type === EPollType.PRE)) {
                dispatch(setDebateStage(EDebateStage.OPENNESS_POLL));
            }
        }
    }, [polls]);

    if (!startupSeen) {
        return <StartupScreen />;
    }

    switch (appState) {
        case EAppState.LOADED:
            if (busy) {
                return <BusyComponent />;
            }
            switch (debateStage) {
                case EDebateStage.INTRO_POLL:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <PollScreen
                                type={EPollType.PRE}
                                nextStage={EDebateStage.OPENNESS_POLL}
                            />
                        </ThemeProvider>
                    );
                case EDebateStage.OPENNESS_POLL:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <PollScreen
                                type={EPollType.OPENNESS}
                                nextStage={EDebateStage.DISCUSSION}
                                previousStage={EDebateStage.INTRO_POLL}
                            />
                        </ThemeProvider>
                    );
                case EDebateStage.DISCUSSION:
                    return <DiscussionScreen />;
                case EDebateStage.SUMMARY:
                    return (
                        <ThemeProvider theme={summaryTheme}>
                            <SummaryScreen />
                        </ThemeProvider>
                    );
                case EDebateStage.EXIT_POLL:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <PollScreen
                                type={EPollType.POST}
                                nextStage={EDebateStage.RESULTS}
                                previousStage={EDebateStage.SUMMARY}
                            />
                        </ThemeProvider>
                    );
                case EDebateStage.RESULTS:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <ResultsScreen />
                        </ThemeProvider>
                    );
                case EDebateStage.SHARE:
                    return (
                        <ThemeProvider theme={pollTheme}>
                            <ShareScreen />
                        </ThemeProvider>
                    );

                default:
                    return null;
            }
        default:
            return <StartupScreen />;
    }
};

export default DebateContainer;
