import axios from 'axios';
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { EAppState, EPage, EReturnCode } from '../../../model/enum';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAppState,
    setStartupSeen,
    setWarningMessage,
} from '../../../redux/app/slice';
import { StringHelper } from '../../../helpers/String';
import shopTheme from '../../../themes/shop';
import { UserHelper } from '../../../helpers/User';
import { useNavigate, useParams } from 'react-router-dom';
import { ICoupon } from '../../../model/interface';
import { loadUser } from '../../../redux/user/actions';
import { CheckCircle } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { AppHelper } from '../../../helpers/App';
import BusyComponent from '../../components/Busy';

const CouponScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const appState = useSelector((state: RootState) => state.app.appState);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const navigate = useNavigate();
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const couponKey = useParams<{ key: string }>().key;
    const [coupon, setCoupon] = useState<ICoupon | undefined>();
    const [redeemed, setRedeemed] = useState<boolean>(false);
    const [busy, setBusy] = useState<boolean>(false);

    const handleRedeemCoupon = async () => {
        setBusy(true);
        try {
            const response = await axios.put<{ success: boolean }>(
                `${apiUrl}/coupon?key=${couponKey}&${UserHelper.formatAuth(userAuth!)}`
            );
            if (response?.data?.success === true) {
                setRedeemed(true);
            } else {
                throw new Error();
            }
        } catch (error) {
            dispatch(setWarningMessage('Failed to redeem coupon'));
        } finally {
            setBusy(false);
        }
    };

    const handleCreate = async () => {
        dispatch(setAppState(EAppState.LOADING));
        try {
            const returnCode = await dispatch(loadUser());
            dispatch(setAppState(EAppState.LOADED));
            if (returnCode === EReturnCode.SUCCESS) {
                navigate(EPage.CREATE);
            }
        } catch (error) {
            //
        }
    };

    const handleUpgradeClick = () => {
        navigate(EPage.CREDITS);
    };

    const loadCoupon = async () => {
        try {
            const response = await axios.get<{ coupon: ICoupon }>(
                `${apiUrl}/coupon?key=${couponKey}`
            );
            if (response?.data?.coupon?.key) {
                setCoupon(response.data.coupon);
            } else {
                dispatch(setWarningMessage('Invalid coupon'));
            }
        } catch (error) {
            console.error(error);
        } finally {
            //
        }
    };

    const renderCosts = (costs: string[]) => {
        return costs.reduce(
            (acc, cost, index) => {
                if (index === 0) {
                    return [cost];
                } else {
                    return [...acc, <br key={index} />, cost];
                }
            },
            [] as (string | JSX.Element)[]
        );
    };

    useEffect(() => {
        if (appState === EAppState.LOADED) {
            loadCoupon();
        }
    }, [appState]);

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <ThemeProvider theme={shopTheme}>
            {redeemed ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: '100%', gap: 4 }}
                >
                    <CheckCircle
                        sx={{ width: 128, height: 128, color: green.A400 }}
                    />
                    <Typography variant="h1" align="center">
                        {`${coupon!.credits} credit${coupon!.credits > 1 ? 's' : ''}  redeemed!`}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreate}
                    >
                        Start a debate
                    </Button>
                </Box>
            ) : busy ? (
                <BusyComponent />
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        height: { xs: 'auto', sm: 'auto', md: '100%' },
                        gap: 12,
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            maxWidth: 720,
                            padding: '2rem 0',
                            gap: 4,
                        }}
                    >
                        {coupon === undefined ||
                        appState !== EAppState.LOADED ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Typography variant="h1" align="center">
                                    Debate Credits
                                </Typography>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={{ gap: 6 }}
                                >
                                    <Typography
                                        variant="caption"
                                        align="center"
                                        sx={{ maxWidth: 400, margin: 'auto' }}
                                    >
                                        {StringHelper.insertNonBreakingSpace(
                                            'This site uses AI to generate debates on topics of your choice, from serious to absurd.'
                                        )}
                                    </Typography>
                                    <Typography variant="h3" align="center">
                                        {StringHelper.insertNonBreakingSpace(
                                            `The link you followed entitles the ${coupon.maxRedemptions === 1 ? '' : 'first ' + coupon.maxRedemptions} recipient${coupon.maxRedemptions === 1 ? '' : 's'} to ${coupon.credits} credit${coupon.credits > 1 ? 's' : ''}${coupon.maxRedemptions === 1 ? '' : ' each'}.`
                                        )}
                                    </Typography>
                                    <Typography variant="body2" align="center">
                                        {renderCosts(AppHelper.getCosts())}
                                    </Typography>
                                </Box>
                                <Paper
                                    elevation={9}
                                    sx={{ padding: '2rem 3rem 3rem 3rem' }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        sx={{ gap: 3 }}
                                    >
                                        {coupon.remaining > 0 ? (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleRedeemCoupon}
                                                >
                                                    {`Redeem ${coupon.credits} credit${coupon.credits > 1 ? 's' : ''}`}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                >
                                                    {StringHelper.insertNonBreakingSpace(
                                                        `${coupon.maxRedemptions === 1 ? 'The coupon has' : `All ${coupon.maxRedemptions} coupons have`} already been claimed. You can always buy credits instead.`
                                                    )}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleUpgradeClick}
                                                >
                                                    Buy Credits
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Paper>
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </ThemeProvider>
    );
};

export default CouponScreen;
