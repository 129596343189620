import React, { useEffect } from 'react';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { EPage } from '../../../model/enum';
import { useLocation, useNavigate } from 'react-router-dom';
import DebatesScreen from './Debates';
import CreditsScreen from './Credits';
import { Box, Tab, Tabs } from '@mui/material';
import { setStartupSeen } from '../../../redux/app/slice';
import SSOScreen from './SSO';
import { HUGE_GAPS, LARGE_GAPS, SMALL_GAPS } from '../../../styles';

const AccountContainer: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(setStartupSeen());
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: HUGE_GAPS }}
        >
            <Tabs value={location.pathname}>
                <Tab
                    value={EPage.ACCOUNT_DEBATES}
                    label="Debates"
                    onClick={() => navigate(EPage.ACCOUNT_DEBATES)}
                />
                <Tab
                    value={EPage.ACCOUNT_CREDITS}
                    label="Credits"
                    onClick={() => navigate(EPage.ACCOUNT_CREDITS)}
                />
                <Tab
                    value={EPage.ACCOUNT_SSO}
                    label="Google"
                    onClick={() => navigate(EPage.ACCOUNT_SSO)}
                />
            </Tabs>
            {location.pathname === EPage.ACCOUNT_DEBATES ? (
                <DebatesScreen />
            ) : location.pathname === EPage.ACCOUNT_CREDITS ? (
                <CreditsScreen />
            ) : location.pathname === EPage.ACCOUNT_SSO ? (
                <SSOScreen />
            ) : null}
        </Box>
    );
};

export default AccountContainer;
