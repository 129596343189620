import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EPage } from '../../model/enum';

const RedirectScreen: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(EPage.HOME);
    }, []);

    return null;
};

export default RedirectScreen;
