import { createTheme } from '@mui/material/styles';
import mainTheme from './main';
import legalTheme from './legal';

const legalThemeSmall = createTheme(legalTheme, {
    typography: {
        h1: {
            fontSize: '2rem',
        },
        h3: {
            marginTop: '1rem',
            fontSize: '1rem',
        },
        h4: {
            marginTop: '1rem',
            textTransform: 'uppercase',
            fontSize: '1rem',
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
        },
        caption: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                },
            },
        },
    },
});

export default legalThemeSmall;
