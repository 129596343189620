import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EAppState, EDebateStatus, EPage } from '../../model/enum';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import LogoComponent from '../components/Logo';
import { grey } from '@mui/material/colors';
import { AppHelper } from '../../helpers/App';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import { StringHelper } from '../../helpers/String';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';
import { setStartupSeen } from '../../redux/app/slice';
import { ButtonBase, Paper } from '@mui/material';
import BusyComponent from '../components/Busy';

const StartupScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const appState = useSelector((state: RootState) => state.app.appState);
    const debate = useSelector((state: RootState) => state.debate.active);
    const [busy, setBusy] = useState(
        AppHelper.isOnDebatePage(location.pathname)
    );
    const audioContextRef = useRef<AudioContext | null>(null);
    const navigate = useNavigate();

    const handleLogoClick = () => {
        dispatch(setStartupSeen());
        navigate(EPage.HOME);
    };

    const handleClick = async () => {
        if (process.env.NODE_ENV !== 'development') {
            // AppHelper.enterFullscreen(document.documentElement);
        }
        try {
            if (audioContextRef.current?.state === 'suspended') {
                await audioContextRef.current.resume();
                // console.log(
                //     'AudioContext resumed:',
                //     audioContextRef.current.state
                // );
            }
        } catch (err) {
            console.error('Failed to resume AudioContext:', err);
        }
        dispatch(setStartupSeen());
    };

    const handlePageClick = (page: EPage) => {
        navigate(page);
    };

    useEffect(() => {
        if (debate !== undefined) {
            setBusy(false);
            navigate(AppHelper.makeDebatePath(debate));
        }
    }, [debate]);

    useEffect(() => {
        audioContextRef.current = new (window.AudioContext ||
            (window as any).webkitAudioContext)();
    }, []);

    const thisYear = new Date().getFullYear();
    const yearRange = thisYear === 2024 ? thisYear : `2024-${thisYear}`;

    return busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
                flex: 1,
                height: '100%',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent={{
                    xs: 'flex-start',
                    sm: 'flex-start',
                    md: 'center',
                }}
                sx={{ flex: 1 }}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 10,
                        padding: { xs: 0, sm: 0, md: '1rem 2rem 2rem 2rem' },
                        backgroundColor: {
                            xs: 'transparent',
                            sm: 'transparent',
                            md: grey[900],
                        },
                        boxShadow: {
                            xs: 'none',
                            sm: 'none',
                        },
                        boxSizing: 'border-box',
                        borderRadius: '1rem',
                        maxWidth: 800,
                        position: 'relative',
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Box sx={{ margin: '1rem 0' }}>
                            <ButtonBase onClick={handleLogoClick}>
                                <LogoComponent size="large" />
                            </ButtonBase>
                        </Box>
                        <Typography
                            align="center"
                            variant="h4"
                            sx={{ textTransform: 'uppercase' }}
                        >
                            Real&nbsp;Debate. Fake&nbsp;People.
                        </Typography>
                    </Box>
                    {debate ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ gap: 6 }}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                sx={{ gap: 2 }}
                            >
                                <Typography align="center" variant="h1">
                                    &ldquo;{debate.topic.issue}&rdquo;
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                onClick={handleClick}
                                disabled={appState !== EAppState.LOADED}
                                sx={{ maxWidth: 360 }}
                            >
                                {debate.status === EDebateStatus.SHARING
                                    ? StringHelper.insertNonBreakingSpace(
                                          `Witness the ${AppHelper.getDebateSpiceName(debate.spice)} debate!`
                                      )
                                    : 'Let’s finish this'}
                            </Button>
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={handleClick}
                            disabled={appState !== EAppState.LOADED}
                            startIcon={<ArrowForward />}
                            endIcon={<ArrowBack />}
                        >
                            Enter
                        </Button>
                    )}
                    {/* {isMobile && (
                    <Typography variant="caption">
                        Not yet available on mobile devices.
                    </Typography>
                )} */}
                </Paper>
            </Box>
            <Typography align="center" variant="caption" sx={{ opacity: 0.54 }}>
                ©&nbsp;{yearRange} Bottymouth&nbsp;Entertainment.
                All&nbsp;rights&nbsp;reserved.
                <br />
                <Link onClick={() => handlePageClick(EPage.TERMS_OF_USE)}>
                    Terms&nbsp;of&nbsp;Use
                </Link>{' '}
                |{' '}
                <Link onClick={() => handlePageClick(EPage.PRIVACY_POLICY)}>
                    Privacy&nbsp;Policy
                </Link>
            </Typography>
        </Box>
    );
};

export default StartupScreen;
