import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const debateTheme = createTheme(mainTheme, {
    typography: {
        h1: {
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '2.4rem',
            },
        },
    },
});

export default debateTheme;
