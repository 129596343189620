const MILLISECONDS_IN_SECOND = 1000;

export class DateTimeHelper {
    public static getCurrentUnixTime = (): number => {
        return Math.floor(Date.now() / MILLISECONDS_IN_SECOND);
    };

    public static daysAgo = (days: number): number => {
        return this.getCurrentUnixTime() - days * 24 * 60 * 60;
    };

    public static formatDate(timestamp: number): string {
        const date = new Date(timestamp * MILLISECONDS_IN_SECOND);
        return date.toLocaleDateString();
    }

    public static makeUnixtime = (
        year: string,
        month: string,
        day: string
    ): number => {
        return (
            new Date(
                parseInt(year, 10),
                parseInt(month, 10),
                parseInt(day, 10)
            ).getTime() / MILLISECONDS_IN_SECOND
        );
    };

    public static isOldEnough(dateOfBirth: number | undefined | null): boolean {
        if (dateOfBirth === undefined || dateOfBirth === null) {
            return false;
        }
        const now = new Date();
        const birthDate = new Date(dateOfBirth * MILLISECONDS_IN_SECOND);
        const age = now.getFullYear() - birthDate.getFullYear();
        const month = now.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && now.getDate() < birthDate.getDate())) {
            return age - 1 >= 13;
        }
        return age >= 13;
    }
}
