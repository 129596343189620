import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { EDebateSpice, EDebateFormat, EPage } from '../../model/enum';
import { StringHelper } from '../../helpers/String';
import { AppHelper } from '../../helpers/App';
import { AppDispatch, RootState } from '../../redux/store';
import { createDebate } from '../../redux/debate/actions';
import { useNavigate } from 'react-router-dom';
import {
    setDefaultFormat,
    setDefaultSpice,
    setRecentTopic,
} from '../../redux/app/slice';
import SelectorComponent from '../components/debate/Selector';
import StartupScreen from './Startup';
import {
    Button,
    Link,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import { KeyboardReturn } from '@mui/icons-material';
import { checkAge } from '../../redux/user/actions';
import BusyComponent from '../components/Busy';
import { ICON_SIZE_SMALL } from '../../styles';
import TermsComponent from '../components/legal/Terms';
import legalThemeSmall from '../../themes/legalSmall';
import { grey } from '@mui/material/colors';
import { UserHelper } from '../../helpers/User';
import { setUserInfo } from '../../redux/user/slice';

const CreateScreen: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const theme = useTheme();
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const defaultSpice = useSelector(
        (state: RootState) => state.app.defaultSpice
    );
    const defaultFormat = useSelector(
        (state: RootState) => state.app.defaultFormat
    );
    const startupSeen = useSelector(
        (state: RootState) => state.app.startupSeen
    );
    const recentTopic = useSelector(
        (state: RootState) => state.app.recentTopic
    );
    const debate = useSelector((state: RootState) => state.debate.active);
    const credits = useSelector((state: RootState) => state.user.credits);
    const userInfo = useSelector((state: RootState) => state.user.info);
    const [topic, setTopic] = useState(recentTopic || '');
    const [busy, setBusy] = useState<boolean>(false);
    const [spice, setSpice] = useState<EDebateSpice>(defaultSpice);
    const [format, setFormat] = useState<EDebateFormat>(defaultFormat);
    const [showTerms, setShowTerms] = useState<boolean>(false);
    const [termsAgreed, setTermsAgreed] = useState<boolean>(
        (userInfo?.agreed || 0) > 0 || false
    );
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const textfieldRef = useRef<HTMLInputElement>(null);

    const handleMoreClick = () => {
        navigate(EPage.HOME);
    };

    const handleAgreeClick = async () => {
        setBusy(true);
        try {
            const response = await axios.post<{ success: boolean }>(
                `${apiUrl}/agree?${UserHelper.formatAuth(userAuth)}`
            );
            if (response?.data?.success === true) {
                setTermsAgreed(true);
                dispatch(setUserInfo({ ...userInfo!, agreed: 1 }));
            } else {
                setBusy(true);
            }
        } catch (err) {
            setBusy(true);
        }
    };

    const handleSelectSpice = async (s: EDebateSpice) => {
        if (await dispatch(checkAge(s))) {
            setSpice(s);
            dispatch(setDefaultSpice(s));
            focusInput();
        }
    };

    const handleSelectFormat = (f: EDebateFormat) => {
        setFormat(f);
        dispatch(setDefaultFormat(f));
        focusInput();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmitTopic();
        }
    };

    const handleBlur = () => {
        if (AppHelper.isMobileDevice()) {
            handleSubmitTopic();
        }
    };

    const handleSubmitTopic = async () => {
        if (StringHelper.isEmpty(topic)) {
            return;
        }

        if (credits <= 0) {
            dispatch(setRecentTopic(topic));
            navigate(EPage.CREDITS);
        }

        if (!termsAgreed) {
            setShowTerms(true);
        } else {
            setBusy(true);
            const result = await dispatch(createDebate(topic, spice, format));
            if (!result) {
                setBusy(false);
            }
        }
    };

    const focusInput = () => {
        textfieldRef.current?.focus();
    };

    useEffect(() => {
        if (showTerms && termsAgreed) {
            handleSubmitTopic();
        }
    }, [termsAgreed]);

    useEffect(() => {
        if (debate !== undefined) {
            navigate(AppHelper.makeDebatePath(debate));
        }
    }, [debate]);

    if (!startupSeen) {
        return <StartupScreen />;
    }

    return busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%',
                gap: 4,
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ gap: { xs: 3, sm: 3, md: 6 }, height: '100%' }}
            >
                {!showTerms && (
                    <SelectorComponent
                        action="Start"
                        selectedSpice={spice}
                        selectedFormat={format}
                        onSelectSpice={handleSelectSpice}
                        onSelectFormat={handleSelectFormat}
                    />
                )}
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    flex="0 0 auto"
                    sx={{ gap: 1, width: '100%', maxWidth: 720 }}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            position: 'relative',
                            gap: 1,
                            width: '100%',
                        }}
                    >
                        <TextField
                            inputRef={textfieldRef}
                            multiline={true}
                            autoFocus={true}
                            disabled={showTerms}
                            variant="outlined"
                            spellCheck={false}
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            fullWidth
                            margin="normal"
                            slotProps={{
                                htmlInput: {
                                    maxLength: 256,
                                },
                            }}
                        />
                        {StringHelper.isNotEmpty(topic) && (
                            <KeyboardReturn
                                sx={{
                                    ...ICON_SIZE_SMALL,
                                    ...{
                                        opacity: 0.7,
                                        position: 'absolute',
                                        bottom: 16,
                                        right: theme.spacing(2),
                                        zIndex: 10,
                                        backgroundColor: 'black',
                                    },
                                }}
                            />
                        )}
                    </Box>
                </Box>
                {showTerms && (
                    <>
                        <Typography
                            variant="caption"
                            align="center"
                            flex="0 0 auto"
                        >
                            Because this is your first time, please review our
                            Terms of Use and indicate your agreement below.
                        </Typography>
                        <ThemeProvider theme={legalThemeSmall}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                sx={{
                                    borderRadius: 2,
                                    padding: '1rem',
                                    backgroundColor: grey[800],
                                    gap: 1,
                                    height: '50vh',
                                    overflowY: 'auto',
                                }}
                                flex="1 1 auto"
                            >
                                <TermsComponent />
                                <Button
                                    variant="contained"
                                    onClick={handleAgreeClick}
                                    sx={{ margin: '1rem 0' }}
                                >
                                    I agree
                                </Button>
                            </Box>
                        </ThemeProvider>
                    </>
                )}
            </Box>
            {!showTerms && (
                <Typography
                    variant="caption"
                    align="center"
                    color="textSecondary"
                >
                    or
                    <br />
                    <Link onClick={handleMoreClick}>
                        Browse trending debates
                    </Link>
                </Typography>
            )}
        </Box>
    );
};

export default CreateScreen;
