import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const snackBarTheme = createTheme(mainTheme, {
    palette: {
        secondary: {
            main: '#000',
        },
    },
    typography: {
        body2: {
            textTransform: 'none',
            fontSize: '1.4rem',
            textAlign: 'center',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    padding: '1rem',
                },
            },
        },
    },
});

export default snackBarTheme;
