import axios from 'axios';
import {
    ArrowBack,
    ArrowForward,
    Check,
    Speed,
    SwapHoriz,
} from '@mui/icons-material';
import {
    Box,
    Button,
    Fab,
    IconButton,
    Link,
    Paper,
    Typography,
} from '@mui/material';
import { amber, blue, grey, red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import {
    EPollType,
    EDebatePosition,
    EDebateStage,
    EPage,
} from '../../../model/enum';
import { StringHelper } from '../../../helpers/String';
import { deleteDebate, savePollResponse } from '../../../redux/debate/actions';
import { resetDebate, setDebateStage } from '../../../redux/debate/slice';
import { useEffect, useState } from 'react';
import { PollHelper } from '../../../helpers/Poll';
import BusyComponent from '../../components/Busy';
import {
    ICON_SIZE_MEDIUM,
    ICON_SIZE_SMALL,
    LARGE_GAPS,
    MEDIUM_GAPS,
    SMALL_GAPS,
} from '../../../styles';
import { ArrayHelper } from '../../../helpers/Array';
import { useNavigate } from 'react-router-dom';
import { AppHelper } from '../../../helpers/App';
import { UserHelper } from '../../../helpers/User';
import { DEBATE_ID_PARAM } from '../../../model/constant';

interface IProps {
    type: EPollType;
    nextStage: EDebateStage;
    previousStage?: EDebateStage;
}

const PollScreen: React.FC<IProps> = ({
    type: pollType,
    nextStage,
    previousStage,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const debate = useSelector((state: RootState) => state.debate.active);
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const polls = useSelector((state: RootState) => state.debate.polls);
    const [busy, setBusy] = useState(true);
    const [alreadyVoted, setAlreadyVoted] = useState<boolean | undefined>();
    const [pollValue, setPollValue] = useState<boolean | undefined>();
    const streamUrl = process.env.REACT_APP_STREAM_URL;
    const currentStep = 0;

    const handleClick = (value: boolean) => {
        setPollValue(value);
        AppHelper.scrollToBottom();
    };

    const handleNextClick = async () => {
        setBusy(true);
        if (!alreadyVoted) {
            await dispatch(savePollResponse(pollType, pollValue!));
        }
        dispatch(setDebateStage(nextStage));
    };

    const handleCancelClick = async () => {
        setBusy(true);
        await dispatch(deleteDebate(debate!.key));
        dispatch(resetDebate());
        navigate(EPage.CREATE);
    };

    const handleBackClick = async () => {
        dispatch(setDebateStage(previousStage!));
    };

    useEffect(() => {
        const vote = PollHelper.getValue(polls, pollType);
        setAlreadyVoted(vote !== undefined);
        setPollValue(vote);
        setBusy(false);
        AppHelper.scrollToTop();
    }, [polls, pollType]);

    useEffect(() => {
        if (pollType === EPollType.PRE) {
            axios.get(
                `${streamUrl}/discussion?${DEBATE_ID_PARAM}=${encodeURIComponent(debate!.key)}&step=${currentStep}&warmup=true&${UserHelper.formatAuth(userAuth!)}`
            );
        }
    }, []);

    return debate === undefined || alreadyVoted === undefined || busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={{
                xs: 'flex-start',
                sm: 'flex-start',
                md: 'center',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: LARGE_GAPS,
                    maxWidth: 960,
                    padding: '2rem 0',
                }}
            >
                {pollType === EPollType.PRE &&
                    ArrayHelper.isEmpty(debate.discussion) && (
                        <Typography
                            variant="caption"
                            align="left"
                            sx={{
                                width: '100%',
                            }}
                        >
                            <Link
                                onClick={handleCancelClick}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: grey[500],
                                    textDecoration: 'none',
                                    gap: 1,
                                    '&:hover': {
                                        color: amber[500],
                                    },
                                }}
                            >
                                <ArrowBack sx={{ width: 24, height: 24 }} />
                                {StringHelper.insertNonBreakingSpace(
                                    'Cancel this, try a different topic'
                                )}
                            </Link>
                        </Typography>
                    )}
                <Paper elevation={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ gap: SMALL_GAPS }}
                    >
                        {pollType === EPollType.PRE ? (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                sx={{ gap: 1 }}
                            >
                                <Speed sx={ICON_SIZE_MEDIUM} />
                                <Typography variant="h1" align="center">
                                    What do YOU think?
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {StringHelper.insertNonBreakingSpace(
                                        `The topic is “${debate!.topic.issue}.”`
                                    )}
                                    <br />
                                    {StringHelper.insertNonBreakingSpace(
                                        `If you had to pick a side, which would you choose?`
                                    )}
                                </Typography>
                            </Box>
                        ) : pollType === EPollType.OPENNESS ? (
                            <>
                                <SwapHoriz />
                                <Typography variant="h1" align="center">
                                    Open to change?
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {StringHelper.insertNonBreakingSpace(
                                        'Are you open to changing your mind on this topic, after listening to the debate?'
                                    )}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Speed />
                                <Typography variant="h1" align="center">
                                    Exit poll
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {StringHelper.insertNonBreakingSpace(
                                        'After listening to the debate, which side do you think won?'
                                    )}
                                </Typography>
                            </>
                        )}
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ gap: MEDIUM_GAPS }}
                        >
                            <Box
                                display="flex"
                                flexDirection={{ xs: 'column', sm: 'row' }}
                                sx={{
                                    marginTop: 1,
                                    gap: LARGE_GAPS,
                                    minHeight: '8rem',
                                }}
                            >
                                <Button
                                    variant={
                                        pollValue === false
                                            ? 'text'
                                            : 'contained'
                                    }
                                    onClick={() => handleClick(true)}
                                    disabled={alreadyVoted}
                                    endIcon={
                                        pollValue === true && (
                                            <Check sx={ICON_SIZE_SMALL} />
                                        )
                                    }
                                    sx={{
                                        flex: 1,
                                        backgroundColor:
                                            pollValue !== undefined
                                                ? undefined
                                                : red[800],
                                        color:
                                            pollValue !== undefined
                                                ? undefined
                                                : 'white',
                                    }}
                                >
                                    {pollType === EPollType.OPENNESS
                                        ? 'Yes'
                                        : StringHelper.insertNonBreakingSpace(
                                              debate!.topic.position[
                                                  EDebatePosition.SIDE_A
                                              ]
                                          )}
                                </Button>
                                <Button
                                    variant={
                                        pollValue === true
                                            ? 'text'
                                            : 'contained'
                                    }
                                    onClick={() => handleClick(false)}
                                    disabled={alreadyVoted}
                                    endIcon={
                                        pollValue === false && (
                                            <Check sx={ICON_SIZE_SMALL} />
                                        )
                                    }
                                    sx={{
                                        flex: 1,
                                        backgroundColor:
                                            pollValue !== undefined
                                                ? undefined
                                                : blue[800],
                                        color:
                                            pollValue !== undefined
                                                ? undefined
                                                : 'white',
                                    }}
                                >
                                    {pollType === EPollType.OPENNESS
                                        ? 'No'
                                        : StringHelper.insertNonBreakingSpace(
                                              debate!.topic.position[
                                                  EDebatePosition.SIDE_B
                                              ]
                                          )}
                                </Button>
                            </Box>
                            {pollType !== EPollType.OPENNESS && (
                                <Typography
                                    variant="caption"
                                    align="center"
                                    sx={{ opacity: 0.8 }}
                                >
                                    {pollValue === undefined
                                        ? 'Choose what you actually believe'
                                        : 'You’ve made your choice.'}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Paper>
                <Box display="flex" alignItems="center" sx={{ gap: 4 }}>
                    {previousStage !== undefined &&
                        (alreadyVoted || pollType === EPollType.POST) && (
                            <IconButton onClick={handleBackClick}>
                                <ArrowBack />
                            </IconButton>
                        )}
                    <Fab
                        color="primary"
                        onClick={handleNextClick}
                        disabled={pollValue === undefined}
                    >
                        <ArrowForward />
                    </Fab>
                </Box>
            </Box>
        </Box>
    );
};

export default PollScreen;
