import axios from 'axios';
import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { EAppState, EPage } from '../../model/enum';
import { UserHelper } from '../../helpers/User';
import { IUserLoadResponse } from '../../model/interface';
import { processLoggedInUser } from '../../redux/user/actions';
import { setAppState, setErrorMessage } from '../../redux/app/slice';

const GoogleComponent: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const apiUrl = `${process.env.REACT_APP_API_URL}/auth`;

    const showError = () => {
        dispatch(setErrorMessage('Connection to Google failed'));
    };

    const handleLoginSuccess = async (
        credentialResponse: CredentialResponse
    ) => {
        // console.log('credentialResponse', credentialResponse, userAuth);
        const idToken = credentialResponse.credential;
        // console.log('userAuth', userAuth);
        try {
            const response = await axios.post<IUserLoadResponse>(
                `${apiUrl}/google?${UserHelper.formatAuth(userAuth)}`,
                {
                    idToken,
                }
            );
            // console.log('response', response);
            const userResponse: IUserLoadResponse | undefined = response?.data;
            if (userResponse !== undefined) {
                await dispatch(processLoggedInUser(userResponse));
                dispatch(setAppState(EAppState.LOADED));
            } else {
                UserHelper.deleteStashedIdProvider();
            }
        } catch (err) {
            showError();
            UserHelper.deleteStashedIdProvider();
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ gap: 2 }}
        >
            <GoogleLogin
                text="continue_with"
                shape="pill"
                onSuccess={handleLoginSuccess}
                onError={() => {
                    // console.log('Login Failed');
                }}
            />
            <Typography
                variant="caption"
                align="center"
                color="textSecondary"
                sx={{ maxWidth: 540 }}
            >
                Your email address will be stored securely and not shared with
                anyone – see the{' '}
                <Link onClick={() => navigate(EPage.PRIVACY_POLICY)}>
                    Privacy&nbsp;Policy
                </Link>
                .
            </Typography>
        </Box>
    );
};

export default GoogleComponent;
