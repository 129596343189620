import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ICredit } from '../../../model/interface';
import { ECreditType, EPage } from '../../../model/enum';
import { Button, Paper, ThemeProvider, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AppDispatch, RootState } from '../../../redux/store';
import { ArrayHelper } from '../../../helpers/Array';
import { UserHelper } from '../../../helpers/User';
import { ContentCopy, Link } from '@mui/icons-material';
import { AppHelper } from '../../../helpers/App';
import { setSuccessMessage, setWarningMessage } from '../../../redux/app/slice';
import { DateTimeHelper } from '../../../helpers/DateTime';
import creditTheme from '../../../themes/credit';
import { SMALL_GAPS } from '../../../styles';

const CreditsScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const userCredits = useSelector((state: RootState) => state.user.credits);
    const [credits, setCredits] = useState<ICredit[] | undefined>();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

    const fetchCredits = async () => {
        try {
            const response = await axios.get<{ credits: ICredit[] }>(
                `${apiUrl}/credits?${UserHelper.formatAuth(userAuth!)}`
            );
            const credits = response.data.credits;
            setCredits(credits);
        } catch (error) {
            console.error(error);
        } finally {
            //
        }
    };

    const handleValueCopy = (reference: string) => {
        if (AppHelper.copyToClipboard(reference)) {
            dispatch(setSuccessMessage('Reference copied to your clipboard'));
        } else {
            dispatch(
                setWarningMessage(
                    'That didn’t work – please copy the reference manually'
                )
            );
        }
    };

    const handleLinkCopy = (couponId: string) => {
        if (
            AppHelper.copyToClipboard(
                AppHelper.makeSiteLink(EPage.COUPON, couponId)
            )
        ) {
            dispatch(setSuccessMessage('Link copied to your clipboard'));
        } else {
            dispatch(
                setWarningMessage(
                    'That didn’t work – please copy the reference manually'
                )
            );
        }
    };

    useEffect(() => {
        fetchCredits();
    }, [userAuth]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            sx={{
                paddingTop: { xs: '1rem', sm: '2rem' },
                paddingBottom: { xs: '1rem', sm: '2rem' },
            }}
        >
            {credits === undefined ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        maxWidth: 480,
                        gap: 2,
                    }}
                >
                    <CircularProgress
                        color="primary"
                        size="large"
                        sx={{ width: '50%', maxWidth: 128 }}
                    />
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        maxWidth: 1200,
                        gap: SMALL_GAPS,
                    }}
                >
                    <Typography variant="h1">Credit History</Typography>
                    <Typography variant="h2">
                        {`Balance: ${Math.max(0, userCredits || 0)} credit${userCredits === 1 ? '' : 's'}`}
                    </Typography>
                    {ArrayHelper.isEmpty(credits) ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ gap: 5 }}
                        >
                            <Typography variant="body1">
                                You don’t have any credits.
                            </Typography>
                            <Button
                                variant="outlined"
                                onClick={() => navigate(EPage.CREDITS)}
                            >
                                Get some
                            </Button>
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ gap: 2, marginTop: 1 }}
                        >
                            <ThemeProvider theme={creditTheme}>
                                <TableContainer
                                    component={Paper}
                                    sx={{ maxHeight: '50vh' }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body1">
                                                        Date
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body1">
                                                        Credits
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body1">
                                                        Type
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body1">
                                                        Reference
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {credits.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        <Typography variant="body1">
                                                            {DateTimeHelper.formatDate(
                                                                row.created
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="body1">
                                                            {row.amount > 0
                                                                ? '+'
                                                                : ''}
                                                            {row.amount}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="body1">
                                                            {AppHelper.getCreditTypeName(
                                                                row.type
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.type ===
                                                        ECreditType.SPEND ? (
                                                            <Button
                                                                onClick={() =>
                                                                    navigate(
                                                                        EPage.DEBATE.replace(
                                                                            ':key',
                                                                            row.redemptionId
                                                                        )
                                                                    )
                                                                }
                                                                endIcon={
                                                                    <Link />
                                                                }
                                                            >
                                                                <Typography
                                                                    component="span"
                                                                    variant="caption"
                                                                >
                                                                    {
                                                                        row.redemptionId
                                                                    }
                                                                </Typography>
                                                            </Button>
                                                        ) : row.type ===
                                                              ECreditType.COUPON ||
                                                          row.type ===
                                                              ECreditType.GIFT ? (
                                                            <Button
                                                                onClick={() =>
                                                                    handleLinkCopy(
                                                                        row.redemptionId
                                                                    )
                                                                }
                                                                endIcon={
                                                                    <ContentCopy />
                                                                }
                                                            >
                                                                <Typography
                                                                    component="span"
                                                                    variant="caption"
                                                                >
                                                                    {
                                                                        row.redemptionId
                                                                    }
                                                                </Typography>
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={() =>
                                                                    handleValueCopy(
                                                                        row.redemptionId
                                                                    )
                                                                }
                                                                endIcon={
                                                                    <ContentCopy />
                                                                }
                                                            >
                                                                <Typography
                                                                    component="span"
                                                                    variant="caption"
                                                                >
                                                                    {
                                                                        row.redemptionId
                                                                    }
                                                                </Typography>
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ThemeProvider>
                            <Button
                                variant="outlined"
                                onClick={() => navigate(EPage.CREDITS)}
                            >
                                Get some more
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default CreditsScreen;
