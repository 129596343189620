import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { FormatQuote, Pause, PlayArrow } from '@mui/icons-material';
import { ButtonBase, CircularProgress } from '@mui/material';

import { IDebateTopic, IParticipant } from '../../../model/interface';
import { EDebatePosition } from '../../../model/enum';
import AvatarComponent from './Avatar';
import { ColorHelper } from '../../../helpers/Color';
import participantTheme from '../../../themes/participant';

interface IParticipantProps {
    speaker: IParticipant;
    debateTopic: IDebateTopic;
    highlighted?: boolean;
    buffering?: boolean;
    speaking?: boolean;
    mouthClosed?: boolean;
    done?: boolean;
    paused?: boolean;
    handlePausePlayAudio?: () => void;
}

const ParticipantComponent: React.FC<IParticipantProps> = ({
    speaker,
    debateTopic,
    highlighted,
    buffering,
    speaking,
    mouthClosed,
    done,
    paused,
    handlePausePlayAudio,
}) => {
    return (
        <ThemeProvider theme={participantTheme}>
            <ButtonBase
                onClick={
                    handlePausePlayAudio !== undefined
                        ? handlePausePlayAudio
                        : undefined
                }
                disabled={!highlighted || buffering}
                sx={{
                    width:
                        speaker.position === EDebatePosition.MODERATOR
                            ? undefined
                            : '100%',
                    height:
                        highlighted === undefined
                            ? '100%'
                            : { md: 'auto', sm: 250 },
                    alignItems:
                        highlighted === undefined ? 'flex-start' : undefined,
                    boxSizing: 'border-box',
                    filter: highlighted === false ? 'grayscale(1)' : 'none',
                    backgroundColor: ColorHelper.getBackground(
                        speaker.position
                    ),
                    gap: 2,
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={{
                        xs: 'column',
                        sm: 'row',
                    }}
                    sx={{
                        position: 'relative',
                        gap: {
                            xs: 2,
                            sm:
                                speaker.position === EDebatePosition.MODERATOR
                                    ? 2
                                    : 6,
                        },
                    }}
                >
                    {highlighted && !done && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                backgroundColor: ColorHelper.getBackground(
                                    speaker.position
                                ),
                                position: 'absolute',
                                top: '-0.8rem',
                                right: '-0.5rem',
                                zIndex: 100,
                                borderWidth: buffering ? 0 : 2,
                                borderStyle: 'solid',
                                borderRadius: '50%',
                                borderColor: 'rgba(255, 255, 255, 0.3)',
                                width: '4rem',
                                height: '4rem',
                            }}
                        >
                            {buffering ? (
                                <Box>
                                    <CircularProgress
                                        sx={{ width: 48, height: 48 }}
                                    />
                                </Box>
                            ) : paused ? (
                                <PlayArrow />
                            ) : (
                                <Pause />
                            )}
                        </Box>
                    )}
                    <AvatarComponent
                        speaking={speaking}
                        buffering={highlighted && buffering}
                        mouthClosed={mouthClosed}
                        backgroundColor={ColorHelper.getAvatar(
                            speaker.position
                        )}
                        speaker={speaker}
                        size={highlighted === undefined ? 'medium' : 'large'}
                    />
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems={{ xs: 'center', sm: 'flex-start' }}
                        sx={{
                            gap:
                                speaker.position === EDebatePosition.MODERATOR
                                    ? 0
                                    : { xs: 1, sm: 1, md: 2 },
                        }}
                    >
                        <Typography variant="caption">
                            {speaker.voice}
                        </Typography>
                        <Typography variant="h1">{speaker.name}</Typography>
                        {speaker.position === EDebatePosition.MODERATOR ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    textAlign: {
                                        xs: 'center',
                                        sm: 'left',
                                    },
                                }}
                            >
                                {speaker.position} from
                                <br />
                                {speaker.hometown}
                            </Typography>
                        ) : (
                            <>
                                {highlighted !== undefined && (
                                    <Typography
                                        variant="caption"
                                        align="left"
                                        sx={{
                                            textAlign: {
                                                xs: 'center',
                                                sm: 'left',
                                            },
                                        }}
                                    >
                                        {`${speaker.age}-year-old ${speaker.occupation} from ${speaker.hometown}`}
                                    </Typography>
                                )}
                                <Box
                                    display="flex"
                                    sx={{ position: 'relative' }}
                                    alignItems={{
                                        xs: 'center',
                                        sm: 'flex-start',
                                    }}
                                >
                                    <FormatQuote
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                sm: 'block',
                                            },
                                            position: 'absolute',
                                            top: '-0.3rem',
                                            left: '-2rem',
                                            fontSize: '2rem',
                                            opacity: 0.3,
                                            transform: 'scaleX(-1)',
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            textAlign: {
                                                xs: 'center',
                                                sm: 'left',
                                            },
                                        }}
                                    >
                                        {debateTopic.position[speaker.position]}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </ButtonBase>
        </ThemeProvider>
    );
};

export default ParticipantComponent;
