import React from 'react';
import { Box, Typography } from '@mui/material';

const TermsComponent: React.FC = () => {
    return (
        <>
            <Typography variant="h1" align="left">
                Terms of Use
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    gap: 1,
                }}
            >
                <Typography variant="caption" align="left">
                    Last Update: December, 2024
                </Typography>
                <Typography variant="body1" align="left">
                    Welcome to botVbot! The following terms of use and end user
                    license agreement (the “Terms of Use”) constitute an
                    agreement between you, or if you are under the age of
                    majority in your local jurisdiction, you, with the consent
                    of your parent or legal guardian (collectively, “user”,
                    “you” or “your”) and Bottymouth Entertainment and its
                    affiliates and subsidiaries (collectively, “Bottymouth”,
                    “our”, “us” or “we”). These Terms of Use govern your use of
                    and access to the Site, Content and/or Services (each as
                    specified below).
                </Typography>
                <Typography variant="h3" align="left">
                    For the purposes of these Terms of Use:
                </Typography>
                <Box component="ul" sx={{ marginLeft: 3 }}>
                    <Typography component="li" variant="body1" align="left">
                        “Account” means an account created on the Site.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Child or Children” means anyone under the age of
                        majority in their local jurisdiction.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Content” means any expression fixed in a tangible
                        medium and includes, without limitation, ideas, text,
                        graphics, avatars, designs, presets (including but not
                        limited to: backgrounds, scene configurations,
                        characters, outfits, body poses, facial expressions,
                        color filters, overlays, effects), combinations of
                        presets, drawings, logos, images, and trademarks (only
                        as an indicator of origin), any of which may be created,
                        submitted, or otherwise made accessible on or through
                        the Site and/or Services.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Parent” means a parent or guardian of a Child or
                        Children.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “botVbot Content” means all Content that is not User
                        Generated Content.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Services” means creation of AI-generated debates
                        accessed through the Site, as well as access to preset
                        Content including but not limited to: participant names,
                        hometowns, occupations, avatars.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “Site” means botvbot.com, and other affiliated
                        subdomains of botvbot.com.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “User” means someone who accesses the Services, Content
                        or other section of the Site.
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        “User Generated Content” or “UGC” means any material
                        whatsoever, including debate topics, that a user,
                        including you, submits, creates, transfers or otherwise
                        makes available by access to the Site or through the
                        Services, including but not limited to ideas,
                        information, data, text posted in any area within the
                        Site or through the Services.
                    </Typography>
                </Box>
                <Typography variant="h3" align="left">
                    Acceptance of Terms and Revisions
                </Typography>
                <Typography variant="body1" align="left">
                    You should carefully review these Terms of Use and, if you
                    do not agree with them, you are not permitted to access or
                    use the Site, Content and/or Services and you should exit
                    the Site, cease using the Site, Content and/or Services
                    immediately and, if you have downloaded or installed any of
                    the Content on to your devices, uninstall the Content from
                    such devices immediately.
                </Typography>
                <Typography variant="body1" align="left">
                    By accessing the Site, submitting information to us
                    (regardless of whether you register an Account with us) or
                    downloading, installing or using any of the Content or
                    Services you accept the terms of these Terms of Use. If you
                    do not accept these Terms of Use you must not submit
                    information to or register an Account with botVbot, access
                    the Site or download, install or use any of the Content or
                    Services.
                </Typography>
                <Typography variant="body1" align="left">
                    We reserve the right, acting in our sole discretion, to
                    revise these Terms of Use at any time. We will give you
                    notice of such revisions by posting the revised Terms of Use
                    at https://botvbot.com/terms. If we make a material change
                    to these Terms of Use we may, in our sole discretion, notify
                    Parents by email of such changes to these Terms of Use. It
                    is your responsibility to ensure that you are aware of the
                    current Terms of Use when you access or use the Site and/or
                    Services. If you continue to access or use any of the Site
                    and/or Services after any revisions to these Terms of Use
                    are posted you will be deemed to have accepted those
                    revisions.
                </Typography>
                <Typography variant="h3" align="left">
                    Minimum Age
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth requires that all users of the Site, Content
                    and/or Services adhere to these Terms of Use. If you are a
                    Child under the age of 13 years old, you may not use
                    botVbot. When you attempt to sign up, you will be directed
                    to ask your parent to sign up instead. Otherwise, if you are
                    under the age of majority in your local jurisdiction and
                    your Parent has not consented to your access and use of the
                    Site, you are not permitted to access or use the Site,
                    Content and/or Services and you must exit the Site, cease
                    using the Site, Content and/or Services immediately until
                    your Parent has consented to your use and access to the
                    Site, Content and/or Services.
                </Typography>
                <Typography variant="body1" align="left">
                    By accessing or using the Site, Content and/or Services you
                    confirm your acknowledgment and acceptance of these Terms of
                    Use, and if you are under the age of majority in your
                    jurisdiction, you confirm that you have the consent of your
                    Parent to use and access the Site and Services.
                </Typography>
                <Typography variant="h3" align="left">
                    Consideration
                </Typography>
                <Typography variant="body1" align="left">
                    For avoidance of doubt, you acknowledge that your acceptance
                    of these Terms of Use is supported by good and valuable
                    consideration including, but not limited to, the limited
                    license granted in these Terms of Use, and you hereby
                    acknowledge the receipt and sufficiency of such
                    consideration.
                </Typography>
                <Typography variant="h3" align="left">
                    Hardware, Software and Connectivity
                </Typography>
                <Typography variant="body1" align="left">
                    You are responsible for providing all equipment and
                    software, including maintaining an Account with any social
                    networking service through which you connect to the Site
                    and/or the associated products and services, necessary to
                    connect to the Site and access the associated products and
                    services, and you are responsible for any fees, including
                    internet connection, mobile data services and similar
                    thirdparty fees that you incur when accessing the Site.
                </Typography>
                <Typography variant="h3" align="left">
                    Suggestions and Feedback
                </Typography>
                <Typography variant="body1" align="left">
                    You agree that any and all feedback that you may provide to
                    Bottymouth, whether solicited or unsolicited, as an Account
                    holder or otherwise (“Feedback”), will be owned by
                    Bottymouth, without providing compensation to you or any
                    other person and without any liability whatsoever, including
                    all intellectual property rights therein and you hereby
                    irrevocably assign all intellectual property rights
                    throughout the world and in perpetuity in and to the
                    Feedback to Bottymouth and waive any and all associated
                    moral rights you may have therein. Upon request from
                    Bottymouth and at Bottymouth's expense, you will fully
                    cooperate and assist with, execute and deliver all further
                    documents to enable Bottymouth to document, acquire, apply
                    for, prosecute, perfect or enforce any intellectual property
                    rights in the Feedback. If for any reason Bottymouth is
                    unable to secure any necessary documentation from you to
                    effect the foregoing further assurances, then you
                    irrevocably designate and appoint Bottymouth and its duly
                    authorized officers and agents as agent and attorney in
                    fact, to act for and on your behalf for the purpose of
                    documenting, acquiring, applying for, prosecuting,
                    perfecting or enforcing such intellectual property rights in
                    the Feedback.
                </Typography>
                <Typography variant="h3" align="left">
                    Data Collection and Ownership
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth may collect data which is anonymous and
                    associated with your computer which includes, but is not
                    limited to, your city, actions you take within and outside
                    the Content and Services, your hardware/software/firmware,
                    the browser you use, the date and time of your use of the
                    Content and Services, your in-media time, activities and/or
                    purchases (“Usage Data”). You agree that your contribution
                    to the Usage Data will be owned by Bottymouth without
                    providing compensation to you or any other person and
                    without any liability whatsoever. Usage Data does not
                    include any personal information.
                </Typography>
                <Typography variant="h3" align="left">
                    Laws and Regulations
                </Typography>
                <Typography variant="body1" align="left">
                    Your access to and use of the Site, Content and/or Services
                    is subject to all applicable international, federal,
                    provincial, state and local laws and regulations.
                </Typography>
                <Typography variant="body1" align="left">
                    Specifically, Bottymouth intends to comply with relevant
                    privacy laws in the jurisdiction of any particular user,
                    including:
                </Typography>
                <Box component="ul" sx={{ marginLeft: 3 }}>
                    <Typography component="li" variant="body1" align="left">
                        Canada: the Personal Information Protection and
                        Electronic Documents Act (“PIPEDA”)
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        United States: the Children’s Online Privacy Protection
                        Act (“COPPA”); the Family Educational Rights and Privacy
                        Act (“FERPA”)
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        European Union: GDPR
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        United Kingdom: UK GDPR
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        Australia: the Privacy Act 1988 and Privacy Principles
                        of Australia (“APP”)
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        New Zealand: the Privacy Act 2020 and Privacy Principles
                        of New Zealand
                    </Typography>
                </Box>
                <Typography variant="h3" align="left">
                    Intellectual Property Rights
                </Typography>
                <Typography variant="body1" align="left">
                    The trade-marks, logos, signs, symbols, images and brands
                    (“Marks”) displayed on the Site are the property of
                    Bottymouth. You are prohibited from using any Marks for any
                    purpose including, but not limited to, use as metatags on
                    other pages or sites on the Internet without the written
                    permission of Bottymouth, which permission may be withheld
                    in the sole discretion of Bottymouth. All information and
                    content available on or through the Site, Content and/or
                    Services is protected by copyright and/or other intellectual
                    property laws. You are prohibited from modifying, copying,
                    distributing, transmitting, displaying, publishing, selling,
                    licensing, creating derivative works or using any Content
                    for commercial and/or public purposes.
                </Typography>
                <Typography variant="h3" align="left">
                    License
                </Typography>
                <Typography variant="h4" align="left">
                    Grant of Limited License
                </Typography>
                <Typography variant="body1" align="left">
                    Subject to your strict compliance with these Terms of Use,
                    Bottymouth hereby grants you a limited, revocable,
                    non-exclusive, personal, non-transferable license, without
                    the right to sublicense, to access and use the (i) Site, and
                    (ii) Content and/or Services which you have downloaded,
                    installed, purchased or subscribed for, as applicable, only
                    for non-commercial purposes (“Limited License”). You shall
                    retain ownership of your User Generated Content, such as
                    original storylines and text. Otherwise, you shall not
                    acquire any ownership rights in any of the botVbot Content,
                    Services or the Site. Except as expressly permitted under
                    these Terms of Use, you will have no rights to, either
                    directly or indirectly, own, use, loan, sell, rent, lease,
                    license, sublicense, assign, copy, translate, transmit,
                    modify, adapt, enhance, improve, create any derivative works
                    from the Site, Content and/or Services, disclose any part or
                    feature of the Site, Content and/or Services that Bottymouth
                    has not publicly disclosed, or to display, distribute,
                    publicly perform or any other way exploit the Site, Content,
                    Services or the Content in whole or in part. You acknowledge
                    and agree that we may modify, suspend or remove any Content
                    or features of the Site, Content and/or Services at any time
                    and from time to time acting in our sole and absolute
                    discretion. All Account holders are granted a Limited
                    License.
                </Typography>
                <Typography variant="h4" align="left">
                    License Term
                </Typography>
                <Typography variant="body1" align="left">
                    The term of your Limited License commences on the date of
                    your acceptance of these Terms of Use and ends on the
                    earlier date of your disposal of the Content and/or Services
                    or Bottymouth’s suspension or cancellation of your Account.
                    Your Limited License shall terminate immediately,
                    automatically and without notice if you attempt to
                    circumvent any technical protection measures used in
                    connection with the Site, Content and/or Services or you
                    otherwise breach these Terms of Use.
                </Typography>
                <Typography variant="h3" align="left">
                    User Accounts
                </Typography>
                <Typography variant="body1" align="left">
                    In order to access and use certain features of the Site,
                    Content and/or Services you will be required to register an
                    Account through the Site, Content or Services as a User.
                    When you register or update an Account, you will be required
                    to provide us with certain personal information, which may
                    include your name and e-mail address. This information will
                    be retained and used by Bottymouth in accordance with its
                    Privacy Policy (as amended from time to time, the “Privacy
                    Policy”), which is incorporated by reference into and forms
                    part of these Terms of Use. You agree that all information
                    provided by you will be accurate and complete and that you
                    will promptly update such information if and when it
                    changes.
                </Typography>
                <Typography variant="body1" align="left">
                    DESPITE ANY OTHER PROVISION IN THESE TERMS OF USE TO THE
                    CONTRARY, YOU ACKNOWLEDGE AND AGREE THAT YOU DO NOT ACQUIRE
                    ANY OWNERSHIP OR OTHER PROPERTY RIGHT IN YOUR ACCOUNT(S) OR
                    STUDENT SUB-ACCOUNT(S), AS SUCH.
                </Typography>
                <Typography variant="body1" align="left">
                    YOU MAY NOT SELL, ASSIGN, TRANSFER, SUBLICENSE, CONVEY OR
                    SHARE WITH ANY OTHER PERSON ANY USER NAMES OR ACCOUNTS OR
                    STUDENT SUB-ACCOUNTS REGISTERED BY YOU AND ANY ATTEMPT TO DO
                    SO WILL ENTITLE BOTTYMOUTH, IN ITS SOLE AND ABSOLUTE
                    DISCRETION AND WITHOUT NOTICE, TO CANCEL OR SUSPEND YOUR
                    ACCOUNT AND, IN SUCH EVENT, YOU WILL NOT BE ENTITLED TO ANY
                    REFUND OF FEES PAID TO BOTTYMOUTH BY YOU OR TO ANY OTHER
                    DAMAGES IN RESPECT THEREOF.
                </Typography>
                <Typography variant="h3" align="left">
                    Login Information
                </Typography>
                <Typography variant="body1" align="left">
                    When you register an Account, you will use Google Single
                    Sign-on, Microsoft sign-on, or Facebook sign-on, and you
                    will be required to permit the Site to access basic
                    information about you, including your name (if any provided)
                    and email address, and to grant express consent to
                    Bottymouth to contact you by way of the e-mail address
                    provided (the “Login Information”).
                </Typography>
                <Typography variant="body1" align="left">
                    You are responsible for protecting the confidentiality of
                    your Account password, and for restricting access to your
                    computer, device or media system platform, and you agree to
                    accept responsibility for all activities that occur under
                    your Account. You are not permitted to share your Account(s)
                    or Login Information or to allow any other person to access
                    to your Account(s). You agree to notify us immediately if
                    you suspect or become aware of any unauthorized use of your
                    password(s), or your Account(s), or any other security
                    breach involving your Account(s) or the Site, Content and/or
                    Services at human@botvbot.com. We are not responsible for
                    any unauthorized access of your Account(s) or password(s)
                    even if you have advised us of such. You are responsible for
                    all activities that occur under your Account(s), including
                    but not limited to purchases, whether or not such activities
                    have been authorized by you.
                </Typography>
                <Typography variant="h3" align="left">
                    Accessing Your Account(s)
                </Typography>
                <Typography variant="body1" align="left">
                    Each time that you access an Account, you represent and
                    warrant that:
                </Typography>
                <Box component="ul" sx={{ marginLeft: 3 }}>
                    <Typography component="li" variant="body1" align="left">
                        you are the person who registered the Account;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        all of the personal information registered in connection
                        with the Account is current, complete and accurate;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are accessing the Site and using the Content and/or
                        Services solely for lawful purposes and in strict
                        compliance with these Terms of Use;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are accessing the Site, Content and/or Services
                        solely for your own personal, non-commercial purposes;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are not, and are not acting on behalf of, any
                        competitor or prospective competitor of Bottymouth or
                        its affiliates or subsidiaries;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are not accessing the Site, Content and/or Services
                        for the purposes of any dispute or litigation involving
                        Bottymouth or its affiliates or subsidiaries;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you are not accessing the Site, Content and/or Services
                        for any illegal purpose or to advertise, solicit or
                        communicate with other users for any commercial purpose;
                        and
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        you will logoff and exit the Account at the end of each
                        session.
                    </Typography>
                </Box>
                <Typography variant="h3" align="left">
                    Unauthorized Access to Password Protected/Secure Areas
                </Typography>
                <Typography variant="body1" align="left">
                    Access to and use of password protected and/or secure areas
                    of the Site, Content and/or Services is restricted to
                    authorized users only. Unauthorized individuals attempting
                    to access the Site and/or these areas of the Site, Content
                    and/or Services may be subject to criminal or civil
                    prosecution or both.
                </Typography>
                <Typography variant="h3" align="left">
                    Information Collection and Use
                </Typography>
                <Typography variant="h4" align="left">
                    Privacy
                </Typography>
                <Typography variant="body1" align="left">
                    By accessing or using the Site, Content and/or Services or
                    otherwise accepting or agreeing to these Terms of Use, you
                    fully and completely agree to the terms and conditions of
                    Bottymouth’s Privacy Policy and to any terms and conditions
                    incorporated therein by reference. Bottymouth’s Privacy
                    Policy sets out how, why and to what extent Bottymouth
                    collects and uses your personal and non-personal information
                    in connection with your access to or use of the Site,
                    Content and/or Services. Bottymouth’s Privacy Policy, as
                    amended from time to time, is available at
                    https://botvbot.com/privacy.
                </Typography>
                <Typography variant="body1" align="left">
                    By accessing or using the Site, Content and/or Services or
                    otherwise accepting or agreeing to these Terms of Use, you
                    fully and completely agree to the terms and conditions of
                    Bottymouth’s Data Processing Addendum and to any terms and
                    conditions incorporated therein by reference. Bottymouth’s
                    Data Processing Addendum sets out how you and Bottymouth
                    intend to comply with applicable data processing, privacy
                    and personal information laws, including permissions for
                    Bottymouth to process personal information. The Data
                    Processing Addendum, as amended from time to time, is
                    available at https://botvbot.com/dpa.
                </Typography>
                <Typography variant="h4" align="left">
                    Transmission of Personal Data
                </Typography>
                <Typography variant="body1" align="left">
                    You acknowledge and agree that by providing Bottymouth with
                    any personal or proprietary user information through the
                    Site, Content and/or Services you consent to the
                    transmission of such personal or proprietary user
                    information over international borders as may be necessary
                    for processing in accordance with Bottymouth’s standard
                    business practices.
                </Typography>
                <Typography variant="h4" align="left">
                    Use of “Cookies”
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth reserves the right to store information on a
                    user’s computer in the form of a “cookie” or similar file
                    for purposes of modifying the Site and the user's experience
                    to reflect users’ preferences only if it is allowed under
                    the laws of your jurisdiction. The Privacy Policy provides
                    additional information regarding Bottymouth’s use of cookies
                    as well as procedures for disabling cookies.
                </Typography>
                <Typography variant="h3" align="left">
                    User Generated Content
                </Typography>
                <Typography variant="body1" align="left">
                    You may choose to share UGC outside of the Site through the
                    “Share”, “Print, and/or Download” links on the Site.
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth is under no obligation to review any material
                    whatsoever including but not limited to information, debates
                    or other user generated content shared by you or any other
                    users and assumes no responsibility or liability relating to
                    any such UGC. Notwithstanding the above, Bottymouth may from
                    time to time monitor, and/or engage a third party to
                    monitor, the UGC and it shall be a material violation of
                    these Terms of Use for you to share or attempt to share any
                    UGC that contains or represents, as determined by Bottymouth
                    acting in its sole discretion:
                </Typography>
                <Box component="ul" sx={{ marginLeft: 3 }}>
                    <Typography component="li" variant="body1" align="left">
                        any unlawful, harmful, threatening, abusive, harassing,
                        defamatory, vulgar, obscene, profane, hateful,
                        discriminatory (based on race, sex, religion,
                        nationality, disability, sexual orientation or age) or
                        otherwise objectionable material of any kind, including,
                        but not limited to, any material which (i) is criminal
                        or which encourages conduct that would constitute a
                        criminal offence, (ii) gives rise to civil liability,
                        (iii) constitutes an invasion of privacy, (iv) otherwise
                        violates any applicable local, state, provincial,
                        national or international law or (v) otherwise harms or
                        can reasonably be expected to create a significant risk
                        of harm to any person or entity;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        advertisements, solicitations or commercial messages or
                        materials of any kind (whether for profit or not);
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        UGC shared by users impersonating others;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        personal information such as messages or text within
                        debates which state phone numbers, government issued
                        identification numbers, account numbers, addresses, or
                        employer references;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        unauthorized messages purporting to be issued by
                        employees or agents of Bottymouth it affiliates or
                        subsidiaries and purporting to speak on behalf of
                        Bottymouth it affiliates or subsidiaries or containing
                        confidential information or expressing opinions
                        concerning Bottymouth its affiliates or subsidiaries;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        materials that offer or encourage unauthorized downloads
                        of any copyrighted or private information or which
                        otherwise infringe or violate any right of a third party
                        including, but not limited to, intellectual property
                        rights, privacy rights, contractual rights, and
                        confidentiality rights;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        materials which are antisocial or disruptive; or
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        materials which in any way transmit any file that
                        contains a virus, corrupted data, Trojan horse,
                        keystroke logger, worm, time bomb, or other programming
                        routines which are capable of tampering with, impairing,
                        damaging, surreptitiously intercepting or mining,
                        scraping or expropriating any system, data or personal
                        information from the Site, Content and/or Service or
                        connected network, and/or which disrupts the use and
                        enjoyment of any of the Site, Content and/or Services or
                        connected network by any person or entity.
                    </Typography>
                </Box>
                <Typography variant="h3" align="left">
                    E-Commerce Transactions
                </Typography>
                <Typography variant="body1" align="left">
                    You acknowledge and agree that only an adult (a person who
                    has attained the age of majority in their local
                    jurisdiction) can enter into and complete a transaction
                    involving the payment of money. Payments can only be made by
                    users who have obtained the age of majority in their
                    jurisdiction or with the consent of their Parent. All
                    payments must be remitted through an Account of a payment
                    provider or processor approved by Bottymouth and you agree
                    to abide by the terms and conditions applicable to that
                    payment method. When you provide any payment account
                    information to Bottymouth, you represent to Bottymouth that
                    you are the authorized user of that payment account.
                    Bottymouth's approved third party payment provider or
                    processor will automatically charge your chosen method of
                    payment on a recurring basis for applicable fees in relation
                    to your Account(s) or automatically for any additional
                    in-media purchases, plus any applicable taxes we are
                    required to collect, where you authorize us to do so. YOU
                    ARE FULLY LIABLE FOR ALL CHARGES TO YOUR PAYMENT ACCOUNT(S).
                    YOU UNDERSTAND AND AGREE THAT ONCE YOU AUTHORIZE US AND/OR
                    OUR APPROVED PAYMENT PROVIDER OR PROCESSOR TO CHARGE YOUR
                    PAYMENT ACCOUNT(S) AND/OR THE ASSOCIATED CREDIT CARD FOR A
                    CERTAIN AMOUNT, SUCH AMOUNT IS NOT REFUNDABLE IN WHOLE OR IN
                    PART AND UNDER ANY CIRCUMSTANCES, INCLUDING, WITHOUT
                    LIMITATION, UPON TERMINATION OR SUSPENSION OF YOUR
                    ACCOUNT(S) FOR ANY REASON, TERMINATION OF THESE TERMS OF USE
                    AND/OR INTERRUPTION OR DISCONTINUATION OF THE SITE, SOME OR
                    ALL OF THE CONTENT AND/OR SERVICES, EXCEPT AT THE SOLE
                    DISCRETION OF BOTTYMOUTH. If you choose a recurring
                    subscription or membership to the Site, Content and/or
                    Services, you acknowledge that payments will be processed
                    automatically until you cancel the subscription or the
                    applicable Account(s). In all cases, your order will
                    represent your offer to Bottymouth to acquire a license to
                    the Content and/or Services ordered, which offer will be
                    deemed to have been accepted by Bottymouth on the earlier of
                    (i) the appearance and availability for use of that Content
                    and/or Service through your Account(s) or (ii) when your
                    payment account is charged.
                </Typography>
                <Typography variant="body1" align="left">
                    When you sign up for a recurring subscription, membership or
                    other ongoing Content and/or Services, you have the right to
                    cancel the recurring arrangement in writing within seven (7)
                    calendar days after you first entered into the recurring
                    arrangement and you will receive a full refund of any fees
                    paid within thirty (30) days. The period for any
                    auto-renewal or recurring arrangement will be the same as
                    the period you originally ordered unless otherwise disclosed
                    to you at the time of purchase. The charges made in
                    connection with any auto-renewal or recurring arrangements
                    will also be at the same rates as were in effect at the time
                    of your original order unless Bottymouth gives you prior
                    notice of any change to such charges before such charges are
                    made for any subsequent renewal period. If you cancel an
                    auto-renewal or recurring arrangement after the seven (7)
                    day cancellation period or after you have accessed,
                    downloaded, installed or used the Content and/or Services
                    you ordered, you will not be entitled to a refund and the
                    cancellation will be effective at the end of the then
                    current recurring period unless you request an earlier
                    termination date in writing. Cancellation requests must be
                    submitted by email to Bottymouth at: human@botvbot.com.
                </Typography>
                <Typography variant="h3" align="left">
                    DCMA Notices
                </Typography>
                <Typography variant="body1" align="left">
                    In some instances, Bottymouth may allow Users to post
                    content on our Site. Bottymouth responds to notices alleging
                    copyright infringement that comply with the United States
                    Digital Millennium Copyright Act (the “DMCA”). If you wish
                    to make a DMCA claim, you must provide the following
                    information in writing in your DMCA notice:
                </Typography>
                <Box component="ul" sx={{ marginLeft: 3 }}>
                    <Typography component="li" variant="body1" align="left">
                        identify the copyrighted work that you claim has been
                        infringed;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        identify the material claimed to be infringing and where
                        it is located;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        provide reasonably sufficient information to allow us to
                        contact you, such as your address, phone number and
                        e-mail address;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        provide a statement that you have a good faith belief
                        that use of the material in the manner complained of is
                        not authorized by the copyright owner, its agent or law;
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        provide a statement, made under penalty of perjury, that
                        the above information is accurate and that you are the
                        copyright owner or are authorized to act on behalf of
                        the owner; and
                    </Typography>
                    <Typography component="li" variant="body1" align="left">
                        provide an electronic or physical signature of a person
                        authorized to act on behalf of the copyright owner.
                    </Typography>
                </Box>
                <Typography variant="body1" align="left">
                    Bottymouth may provide you notice if your communication,
                    post, submission or upload was removed as a result of
                    Bottymouth receiving a DMCA notice from a copyright owner.
                    If you receive notice from Bottymouth, you may provide a
                    counter-notification in writing to Bottymouth’s designated
                    DMCA agent through the means provided below.
                </Typography>
                <Typography variant="body1" align="left">
                    DMCA notices can be sent to Bottymouth by emailing
                    infringement@botvbot.com or by mail:
                </Typography>
                <Typography variant="body1" align="left">
                    Infringement Claims <br />
                    Bottymouth Entertainment <br />
                    PO Box 123 <br />
                    Qualicum Beach, BC, Canada <br />
                    V9K 1S7 <br />
                </Typography>
                <Typography variant="h3" align="left">
                    Warranties and Limitation of Liability
                </Typography>
                <Typography variant="h4" align="left">
                    No Warranties
                </Typography>
                <Typography variant="body1" align="left">
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, ALL
                    CONTENT AND SERVICES ON OR THROUGH THE SITE, CONTENT AND/OR
                    SERVICES ARE PROVIDED TO YOU “AS IS” AND “AS AVAILABLE”
                    WITHOUT ANY WARRANTY OF ANY KIND EITHER EXPRESS OR IMPLIED
                    INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                    MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                    NON-INFRINGEMENT, SECURITY OR ACCURACY. BOTTYMOUTH DOES NOT
                    ENDORSE AND IS NOT RESPONSIBLE FOR THE ACCURACY OR
                    RELIABILITY OF ANY OPINION, ADVICE OR STATEMENT MADE THROUGH
                    THE SITE, CONTENT, SERVICES BY ANY PARTY OTHER THAN
                    BOTTYMOUTH. IT IS THE RESPONSIBILITY OF THE USER TO EVALUATE
                    THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY OPINION,
                    ADVICE OR OTHER CONTENT AVAILABLE THROUGH THE SITE. PLEASE
                    SEEK THE ADVICE OF PROFESSIONALS, AS APPROPRIATE, REGARDING
                    THE EVALUATION OF ANY SPECIFIC OPINION, ADVICE, PRODUCT,
                    SERVICE, OR OTHER CONTENT. SOME JURISDICTIONS MAY NOT ALLOW
                    DISCLAIMERS OF IMPLIED WARRANTIES AND THE ABOVE DISCLAIMERS
                    MAY NOT APPLY TO YOU TO THE EXTENT DISCLAIMED.
                </Typography>
                <Typography variant="h4" align="left">
                    Limitation of Liability for Use of the Site and Content
                </Typography>
                <Typography variant="body1" align="left">
                    THE INFORMATION, CONTENT, SERVICES, PRODUCTS AND
                    DESCRIPTIONS OF CONTENT AND/OR SERVICES PUBLISHED ON THE
                    SITE, OR INCLUDED IN THE CONTENT OR SERVICES, MAY INCLUDE
                    INACCURACIES OR TYPOGRAPHICAL ERRORS, AND, TO THE FULLEST
                    EXTENT PERMITTED BY APPLICABLE LAWS, BOTTYMOUTH SPECIFICALLY
                    DISCLAIMS ANY LIABILITY FOR SUCH INACCURACIES OR ERRORS.
                    CHANGES ARE PERIODICALLY MADE TO THE INFORMATION ON THE SITE
                    OR CONTAINED IN THE CONTENT OR SERVICES. BOTTYMOUTH MAY MAKE
                    IMPROVEMENTS OR CHANGES TO THE SITE, CONTENT AND/OR SERVICES
                    AT ANY TIME.
                </Typography>
                <Typography variant="body1" align="left">
                    YOU AGREE THAT BOTTYMOUTH, ITS AFFILIATES AND SUBSIDIARIES
                    AND ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
                    OR AGENTS WILL NOT BE LIABLE, WHETHER IN CONTRACT, TORT,
                    STRICT LIABILITY, EQUITY OR UNDER ANY OTHER LEGAL THEORY,
                    FOR ANY PERSONAL INJURY, LOSS, ACCIDENT OR DEATH OR ANY
                    DIRECT, INDIRECT, PUNITIVE, CONSEQUENTIAL, INCIDENTAL OR
                    SPECIAL DAMAGES (INCLUDING WITHOUT LIMITATION LOST PROFITS,
                    COST OF PROCURING SUBSTITUTE SERVICE OR LOST OPPORTUNITY)
                    ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SITE,
                    CONTENT, SERVICES OR FOR ANY DELAY OR INABILITY TO USE THE
                    SITE, CONTENT, SERVICES EVEN IF BOTTYMOUTH IS MADE AWARE OF
                    THE POSSIBILITY OF SUCH INJURY, LOSS, ACCIDENT, DEATH OR
                    DAMAGES. THIS LIMITATION ON LIABILITY INCLUDES, BUT IS NOT
                    LIMITED TO, THE TRANSMISSION OF ANY VIRUSES, MALWARE OR
                    MALICIOUS SOFTWARE WHICH MAY INFECT A USER’S EQUIPMENT OR
                    DEVICES, FAILURE OF THE CONTENT AND/OR SERVICES TO BE
                    COMPATIBLE WITH YOUR HARDWARE OR SOFTWARE, FAILURE OF
                    MECHANICAL OR ELECTRONIC EQUIPMENT OR COMMUNICATION OR
                    INTERCONNECT LINES OR FIBER, OR OTHER CONNECTIVITY PROBLEMS
                    (e.g., YOU CANNOT ACCESS YOUR INTERNET SERVICE PROVIDER),
                    UNAUTHORIZED ACCESS, THEFT, LOSS OF DATA, OPERATOR ERRORS,
                    STRIKES OR OTHER LABOUR PROBLEMS OR ANY FORCE MAJEURE EVENT.
                    BOTTYMOUTH CANNOT AND DOES NOT GUARANTEE CONTINUOUS,
                    UNINTERRUPTED, ERROR FREE OR SECURE ACCESS TO THE SITE,
                    CONTENT OR SERVICES. WITHOUT LIMITING THE FOREGOING, UNDER
                    NO CIRCUMSTANCES WILL BOTTYMOUTH BE LIABLE TO YOU FOR ANY
                    AMOUNT THAT EXCEEDS THE AGGREGATE OF ALL AMOUNTS PAID BY YOU
                    TO BOTTYMOUTH.
                </Typography>
                <Typography variant="body1" align="left">
                    APPLICABLE LAWS MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
                    LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
                    ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. HOWEVER,
                    EVEN IF THIS IS THE CASE, IN NO EVENT SHALL THE TOTAL
                    LIABILITY OF BOTTYMOUTH FOR ALL DAMAGES, LOSSES AND CAUSES
                    OF ACTION AND COSTS AND EXPENSES (INCLUDING LEGAL FEES)
                    WHETHER IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO
                    NEGLIGENCE), EQUITY OR UNDER ANY OTHER LEGAL THEORY, RELATED
                    TO YOUR USE OF, OR THE INABILITY TO USE, THE SITE, CONTENT
                    AND/OR SERVICES EXCEED THE AGGREGATE OF ALL AMOUNTS PAID BY
                    YOU TO BOTTYMOUTH.
                </Typography>
                <Typography variant="h3" align="left">
                    Violations of Terms of Use
                </Typography>
                <Typography variant="body1" align="left">
                    You agree and acknowledge that Bottymouth may, in its sole
                    and absolute discretion and without prior notice to you,
                    terminate or suspend your access to the Site and your use of
                    the Services, or any portion thereof, and/or cancel your
                    Account if Bottymouth reasonably believes that you have
                    failed to strictly comply with these Terms of Use or that
                    the use of the Site by you violate the rights of any third
                    party or any applicable laws. In addition to the foregoing,
                    Bottymouth reserves the right to pursue all remedies
                    available at law and in equity for violations of these Terms
                    of Use, including but not limited to the right to block
                    access from a particular Internet address to the Site. You
                    agree and acknowledge that monetary damages may not be an
                    adequate remedy for any violation of these Terms of Use by
                    you and, without limiting any of Bottymouth's other
                    remedies, you hereby consent to, and authorize Bottymouth to
                    obtain, an injunction or other equitable relief from any
                    court of competent jurisdiction. You further authorize
                    Bottymouth to disclose your personally identifiable
                    information where reasonably necessary in connection with
                    the foregoing.
                </Typography>
                <Typography variant="h3" align="left">
                    Indemnification
                </Typography>
                <Typography variant="body1" align="left">
                    AS PERMITTED BY LAW, YOU HEREBY AGREE TO DEFEND, INDEMNIFY
                    AND HOLD HARMLESS BOTTYMOUTH, ITS AFFILIATES AND
                    SUBSIDIARIES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
                    EMPLOYEES, AGENTS, SUCCESSORS, ADMINISTRATORS AND ASSIGNS
                    (THE "INDEMNIFIED PARTIES") FROM AND AGAINST ALL
                    LIABILITIES, CLAIMS, ACTIONS, CAUSES OF ACTION, COSTS AND
                    EXPENSES (INCLUDING LEGAL EXPENSES ON A FULL INDEMNITY BASIS
                    AND ANY OTHER FEES AND EXPENSES INCURRED FOR INVESTIGATING
                    OR DEFENDING ANY ACTION OR THREATENED ACTION, AS WELL AS
                    SETTLEMENT COSTS) WHICH ANY OF THE INDEMNIFIED PARTIES MAY
                    SUFFER OR INCUR IN CONNECTION WITH ANY CLAIM ARISING OUT OF
                    OR RESULTING FROM: (I) YOUR VIOLATION OF ANY OF THESE TERMS
                    OF USE, (II) YOUR USE OR MISUSE OF THE SITE, CONTENT AND/OR
                    SERVICES, INCLUDING BUT NOT LIMITED TO PERSONAL INJURY OR
                    DEATH OR DAMAGES RESULTING FROM YOUR PROVISION OF UGC TO A
                    THIRD PARTY (III) AND/OR YOUR VIOLATION OF ANY LAW OR THIRD
                    PARTY RIGHTS.
                </Typography>
                <Typography variant="body1" align="left">
                    WITHOUT DEROGATING FROM OR EXCUSING YOUR OBLIGATIONS UNDER
                    THIS SECTION WE RESERVE THE RIGHT (AT YOUR EXPENSE), BUT ARE
                    NOT UNDER ANY OBLIGATION, TO ASSUME THE EXCLUSIVE DEFENSE
                    AND CONTROL OF ANY MATTER WHICH IS SUBJECT TO AN
                    INDEMNIFICATION BY YOU EVEN IF YOU CHOOSE TO DEFEND OR
                    SETTLE IT. YOU AGREE NOT TO SETTLE ANY MATTER THAT IS
                    SUBJECT TO AN INDEMNIFICATION BY YOU WITHOUT FIRST OBTAINING
                    OUR EXPRESS APPROVAL.
                </Typography>
                <Typography variant="h3" align="left">
                    Updates and Amendments
                </Typography>
                <Typography variant="body1" align="left">
                    Updates and Discontinuance of Site, Software or Services You
                    acknowledge and agree that the Site, Content and Services
                    are under continual development and may include public
                    patches and/or updates, which are generally available to all
                    users, or private patches and/or updates which are available
                    to a smaller number of users for testing and feedback before
                    being made generally available. You further acknowledge and
                    agree that you may be required to accept public patches and
                    updates to the Site, Content and/or Services from time to
                    time in order to continue to access the Site, Content and/or
                    Services and your related Account(s). You acknowledge and
                    agree that Bottymouth may update, modify, patch and/or
                    discontinue any aspect of the Site, Content and/or Services,
                    by remotely installing updates and patches or otherwise,
                    without your consent or approval and with or without prior,
                    or any, notice to you, unless such update involves the
                    collection of additional personal information not previously
                    consented to.
                </Typography>
                <Typography variant="body1" align="left">
                    You acknowledge that it may be necessary for you to update
                    or upgrade third party software, hardware and devices from
                    time to time in order to continue to access and use the
                    Site, Content and/or Services and your related Account(s).
                    Bottymouth reserves the right to modify or increase the
                    system specifications necessary to access and use the Site,
                    Content and/or Services at any time and without notice and
                    you are responsible for purchasing any necessary additional
                    technology, systems or services in order to continue to
                    access and use the Site, Content, Services and/or your
                    related Account(s) in the event of any change in the system
                    specifications.
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth reserves the right, at any time and without
                    notice or liability to you, to update, modify and/or reset
                    certain parameters of the Site, Content and/or Services any
                    of which may impact the status, progress or achievements of
                    characters, games, groups and/or items under your control or
                    associated with your Account(s).
                </Typography>
                <Typography variant="h3" align="left">
                    Changes to botVbot
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth reserves the right, acting in its sole discretion
                    and with or without prior notice to you, to do any of the
                    following:
                </Typography>
                <Typography variant="body1" align="left">
                    modify, suspend or terminate operation of, or access to, any
                    portion, features or functions of the Site, Content and/or
                    Services including, but not limited to, geographical
                    availability and applicable policies or terms; make changes
                    to any fees or charges, if any, related to your use of the
                    Site, Content and/or Services; make changes to the
                    equipment, hardware or software required to use and access
                    the Site, Content and/or Services; and interrupt the Site,
                    Content and /or Services, or any portion thereof, as
                    necessary to perform routine or non-routine maintenance,
                    error correction or other modifications.
                </Typography>
                <Typography variant="h3" align="left">
                    Termination
                </Typography>
                <Typography variant="body1" align="left">
                    These Terms of Use, as amended from time to time by
                    Bottymouth, shall remain effective until terminated by you
                    or Bottymouth. You may terminate these Terms of Use by
                    discontinuing use of the Site, Content, Services and your
                    associated Account(s) and destroying all electronic and
                    other copies of all related Content, including related
                    documentation. Without limiting any other provision of these
                    Terms of Use or the remedies available to Bottymouth,
                    Bottymouth may immediately terminate these Terms of Use
                    and/or cancel or suspend your Account(s) and access to the
                    Site, Content and/or Services in its sole and absolute
                    discretion and for any reason including, without limitation,
                    if you violate or otherwise fail to strictly comply with any
                    term or provision of these Terms of Use. Upon termination of
                    these Terms of Use for any reason, you must cease use of
                    your Account(s), cease accessing the Site, Content and
                    Services and promptly destroy all electronic and other
                    copies of the Content, including all related documentation.
                </Typography>
                <Typography variant="h3" align="left">
                    General
                </Typography>
                <Typography variant="h4" align="left">
                    International Use
                </Typography>
                <Typography variant="body1" align="left">
                    This Site is operated from and Bottymouth is headquartered
                    in the province of British Columbia, Canada. By electing to
                    access this Site, Content and/or Services from any
                    jurisdiction outside of Canada, you accept full
                    responsibility for ensuring that your use of the Site,
                    Content and/or Services is in compliance with all laws
                    applicable within that other jurisdiction. Bottymouth makes
                    no representation that materials and services available on
                    or through the Site, Content and/or Services are appropriate
                    or available for use in jurisdictions outside of Canada.
                    Notwithstanding the above, Bottymouth always intends to
                    maintain compliance with US privacy laws, including COPPA.
                    You are not permitted to access the Site, Content and/or
                    Services from any jurisdiction in which the Content is
                    illegal and/or accessing the Site, Content and/or Services
                    is illegal. You may not use, export or re-export any
                    materials from this Site, Content and/or Services in
                    violation of any applicable laws or regulations.
                </Typography>
                <Typography variant="h4" align="left">
                    Governing Law
                </Typography>
                <Typography variant="body1" align="left">
                    These Terms of Use and your legal relationship with
                    Bottymouth shall be governed by, and will be enforced,
                    construed and interpreted in accordance with the laws of the
                    province of British Columbia and the federal laws of Canada
                    applicable therein, without regard to its conflicts of laws
                    principles. These Terms of Use shall not be governed by the
                    United Nations Convention on Contracts for the Sale of
                    Goods. The controlling language of these Terms of Use is
                    English.
                </Typography>
                <Typography variant="h4" align="left">
                    Waiver and Severability
                </Typography>
                <Typography variant="body1" align="left">
                    The failure of Bottymouth to enforce any right or provision
                    of these Terms of Use will not constitute a waiver of such
                    right or provision. In the event that any provision of these
                    Terms of Use is held to be invalid or unenforceable by any
                    court of competent jurisdiction, that provision shall be
                    enforced to the maximum extent permissible and the remaining
                    provisions of the Terms of Use will remain in full force and
                    effect. All remedies of Bottymouth hereunder shall be
                    cumulative.
                </Typography>
                <Typography variant="h4" align="left">
                    Assignment
                </Typography>
                <Typography variant="body1" align="left">
                    You may not assign any of your rights or obligations
                    hereunder, whether voluntarily or by operation of law except
                    with the prior express written consent of Bottymouth, which
                    consent may be withheld in Bottymouth’s sole discretion, and
                    any attempted assignment in violation of the foregoing is
                    void. Bottymouth may assign any or all of its rights and
                    obligations hereunder to an affiliate, subsidiary or a
                    successor in title to the Site and/or the business and
                    undertaking of Bottymouth.
                </Typography>
                <Typography variant="h4" align="left">
                    Enurement
                </Typography>
                <Typography variant="body1" align="left">
                    These Terms of Use shall enure to the benefit of and be
                    binding upon the parties and their respective heirs,
                    executors, administrators, successors and permitted assigns.
                </Typography>
                <Typography variant="h4" align="left">
                    Force Majeure
                </Typography>
                <Typography variant="body1" align="left">
                    Bottymouth will not be liable for, or be considered to be in
                    breach of or default under these Terms of Use on account of,
                    any delay or failure to perform as required by these Terms
                    of Use as a result of events beyond the reasonable control
                    of Bottymouth, including without limiting the foregoing
                    where the delay or failure is due to fires or fire related
                    hazards, explosions, power outages, earthquakes, floods,
                    spills, epidemics, acts of God, war or acts of war, acts of
                    terrorism, riots, strikes, lockouts or other labour or
                    contractor actions, acts of public authorities, or delays or
                    defaults caused by common carriers, or other similar events
                    which cannot reasonably be foreseen or provided against.
                </Typography>
                <Typography variant="h4" align="left">
                    Notices
                </Typography>
                <Typography variant="body1" align="left">
                    Any notices or other communications permitted or required
                    hereunder, including but not limited to modifications to
                    these Terms of Use and/or any policy referred to herein,
                    will be in writing and given by Bottymouth (i) via email (in
                    each case to the email address provided by you for your
                    Account(s)) or (ii) by posting to the Site. Notice delivered
                    by email will be deemed to have been received on the date on
                    which such notice is transmitted.
                </Typography>
                <Typography variant="h4" align="left">
                    Dispute Resolution
                </Typography>
                <Typography variant="body1" align="left">
                    You and Bottymouth agree that any dispute, claim or
                    controversy arising out of or relating to these Terms of
                    Use, any policy referred to herein, or the breach,
                    termination, enforcement, interpretation or the validity
                    thereof, or the use of the Site, Content, Services or
                    Content (collectively, the “Disputes”) will be settled by
                    binding arbitration, except that each party retains the
                    right to (i) bring an individual action in the British
                    Columbia Provincial Court (Small Claims Division) if the
                    action is within the jurisdiction of that court and (ii)
                    seek injunctive or other equitable relief in a court of
                    competent jurisdiction to prevent actual or threatened
                    infringement, misappropriation, or violation of a party’s
                    copyrights, trademarks, trade secrets, patents, confidential
                    information or other intellectual property or proprietary
                    rights. You acknowledge and agree that you and Bottymouth
                    are each irrevocably waiving the right to a trial by jury
                    and the right to participate as a plaintiff or a class
                    member in any purported class action or similar
                    representative proceeding. Further, unless you and
                    Bottymouth expressly agree in writing, the arbitrator may
                    not consolidate more than one person’s claims and may not
                    otherwise preside over any form of class or representative
                    proceeding. This “Dispute Resolution” section shall survive
                    any termination of these Terms of Use.
                </Typography>
                <Typography variant="body1" align="left">
                    Any arbitration to be conducted pursuant to this “Dispute
                    Resolution” section shall be conducted before a single
                    arbitrator in accordance with the rules of the British
                    Columbia International Commercial Arbitration Centre for
                    Domestic Disputes. The venue of the arbitration shall be
                    Vancouver, British Columbia, Canada.
                </Typography>
                <Typography variant="h3" align="left">
                    Entire Agreement
                </Typography>
                <Typography variant="body1" align="left">
                    These Terms of Use, together with the Privacy Policy and any
                    supplemental terms and conditions referred to below,
                    constitute the entire agreement between the parties
                    concerning the subject-matter hereof and supersede and
                    replace any and all prior oral or written understandings or
                    agreements between you and Bottymouth regarding the Site,
                    Content, Services and the Content.
                </Typography>
                <Typography variant="h3" align="left">
                    Survival
                </Typography>
                <Typography variant="body1" align="left">
                    The provisions of these Terms of Use which require or
                    contemplate performance after the expiration or termination
                    of these Terms of Use shall remain enforceable
                    notwithstanding such expiration or termination.
                </Typography>
                <Typography variant="h3" align="left">
                    Relationship
                </Typography>
                <Typography variant="body1" align="left">
                    The relationship between the parties is that of independent
                    contractors and neither party shall have the authority to
                    bind the other in any way.
                </Typography>
            </Box>
        </>
    );
};

export default TermsComponent;
