import React from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import logoSmallTheme from '../../themes/logoSmall';
import logoLargeTheme from '../../themes/logoLarge';
import { blue, red } from '@mui/material/colors';

interface IProps {
    size: 'small' | 'large';
}

const LogoComponent: React.FC<IProps> = ({ size }) => {
    return (
        <ThemeProvider
            theme={size === 'small' ? logoSmallTheme : logoLargeTheme}
        >
            <Typography
                variant="body1"
                sx={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                    margin: size === 'small' ? '0 0.5rem' : 0,
                }}
            >
                <Typography
                    component="span"
                    variant="body1"
                    sx={{
                        backgroundColor: red[700],
                        padding: size === 'small' ? '0.17rem' : '0.5rem',
                        borderRadius: size === 'small' ? '0.17rem' : '0.5rem',
                        '@media print': { color: 'white' },
                    }}
                >
                    bot
                </Typography>
                <Typography
                    component="span"
                    variant="body1"
                    sx={{
                        padding: size === 'small' ? '0.08rem' : '0.25rem',
                    }}
                >
                    V
                </Typography>
                <Typography
                    component="span"
                    variant="body1"
                    sx={{
                        backgroundColor: blue[700],
                        padding: size === 'small' ? '0.17rem' : '0.5rem',
                        borderRadius: size === 'small' ? '0.17rem' : '0.5rem',
                        '@media print': { color: 'white' },
                    }}
                >
                    bot
                </Typography>
            </Typography>
        </ThemeProvider>
    );
};

export default LogoComponent;
