import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const BusyComponent: React.FC = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%',
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default BusyComponent;
