import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_MESSAGES, USER_PARAM_A, USER_PARAM_C } from '../model/constant';
import {
    EAppState,
    EIdentityProvider,
    EPage,
    EReturnCode,
} from '../model/enum';
import Box from '@mui/material/Box';
import MenuComponent from './components/Menu';
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import TrendingScreen from './pages/Trending';
import AboutScreen from './pages/info/About';
import TermsScreen from './pages/legal/Terms';
import PrivacyScreen from './pages/legal/Privacy';
import DPAScreen from './pages/legal/DPA';
import { ThemeProvider, Typography, Button } from '@mui/material';
import snackBarTheme from '../themes/snackBar';
import { loadUser } from '../redux/user/actions';
import { AppDispatch, RootState } from '../redux/store';
import {
    setAppState,
    setErrorMessage,
    setRecentTopic,
    setStartupSeen,
} from '../redux/app/slice';
import DebateContainer from './pages/debate/Container';
import CreditScreen from './pages/shop/Purchase';
import CouponScreen from './pages/shop/Coupon';
import CreateScreen from './pages/Create';
import SearchScreen from './pages/Search';
import ContactScreen from './pages/info/Contact';
import { Error } from '@mui/icons-material';
import MessageComponent from './components/Message';
import AccountContainer from './pages/account/Container';
import RedirectScreen from './pages/Redirect';
import { red } from '@mui/material/colors';
import LoginScreen from './pages/account/Login';
import { AppHelper } from '../helpers/App';
import { StringHelper } from '../helpers/String';
import AgeGateComponent from './components/AgeGate';
import { cancelAgePrompt } from '../redux/debate/actions';
import BusyComponent from './components/Busy';
import { ArrayHelper } from '../helpers/Array';
import { resetDebate } from '../redux/debate/slice';

const App: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const appState = useSelector((state: RootState) => state.app.appState);
    const dob = useSelector((state: RootState) => state.user.info?.dob);
    const startupSeen = useSelector(
        (state: RootState) => state.app.startupSeen
    );
    const [showAgeGate, setShowAgeGate] = React.useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const recentTopic = searchParams.get('topic') || undefined;

    const handleReload = () => {
        AppHelper.reload();
    };

    const cancelAgeGate = async () => {
        await dispatch(cancelAgePrompt());
        setShowAgeGate(false);
    };

    useEffect(() => {
        if (StringHelper.isNotEmpty(recentTopic)) {
            dispatch(setRecentTopic(recentTopic));
            dispatch(setStartupSeen());
        }
    }, [recentTopic]);

    useEffect(() => {
        if (dob === null) {
            setShowAgeGate(true);
        } else if (dob !== undefined && dob > 0) {
            setShowAgeGate(false);
        }
    }, [dob]);

    useEffect(() => {
        // auto login from local storage
        const login = async () => {
            dispatch(setAppState(EAppState.LOGGING_IN));
            try {
                const returnCode = await dispatch(loadUser());
                dispatch(setAppState(EAppState.LOADED));
                if (returnCode === EReturnCode.REDIRECT_TO_SSO) {
                    navigate(EPage.LOGIN);
                }
            } catch (error) {
                dispatch(setErrorMessage(error?.toString()));
                dispatch(setAppState(EAppState.ERROR));
            }
        };
        login();
    }, [dispatch]);

    useEffect(() => {
        if (!AppHelper.isOnDebatePage(location.pathname)) {
            console.log('reset');
            dispatch(resetDebate());
        }
        if (location.pathname !== EPage.LOGIN) {
            let userKeyA: string | null = localStorage.getItem(USER_PARAM_A);
            let userKeyC: string | null = localStorage.getItem(USER_PARAM_C);
            if (
                userKeyC === EIdentityProvider.GOOGLE &&
                StringHelper.isEmpty(userKeyA)
            ) {
                navigate(EPage.LOGIN);
            }
        }
    }, [location]);

    if (appState === EAppState.ERROR) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ width: '100vw', height: '100vh' }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: '100%', maxWidth: 540, gap: 4 }}
                >
                    <Error sx={{ width: 128, height: 128, color: red.A400 }} />
                    <Typography variant="body1" align="center">
                        {ArrayHelper.shuffle(ERROR_MESSAGES)[0]}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReload}
                    >
                        Reload
                    </Button>
                </Box>
            </Box>
        );
    }

    if (appState !== EAppState.LOADED) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ width: '100vw', height: '100vh' }}
            >
                <BusyComponent />
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100vw', height: '100vh' }}
        >
            {showAgeGate ? (
                <AgeGateComponent handleCloseClick={cancelAgeGate} />
            ) : (
                <>
                    {startupSeen && <MenuComponent />}
                    <Box
                        display="flex"
                        sx={{
                            boxSizing: 'border-box',
                            height: '100vh',
                            position: 'relative',
                            padding: {
                                xs: 2,
                                sm: 2,
                                md: 4,
                            },
                            paddingTop: {
                                xs: 10,
                                sm: 10,
                                md: 10,
                            },
                        }}
                    >
                        <Routes>
                            <Route
                                path={EPage.HOME}
                                element={<TrendingScreen />}
                            />
                            <Route
                                path={EPage.DEBATE}
                                element={<DebateContainer />}
                            />
                            <Route
                                path={EPage.CREATE}
                                element={<CreateScreen />}
                            />
                            <Route
                                path={EPage.SEARCH}
                                element={<SearchScreen />}
                            />
                            <Route
                                path={EPage.LATEST}
                                element={<TrendingScreen isLatest={true} />}
                            />
                            <Route
                                path={EPage.CREDITS}
                                element={<CreditScreen />}
                            />
                            <Route
                                path={EPage.PURCHASE_COMPLETE}
                                element={
                                    <CreditScreen purchaseComplete={true} />
                                }
                            />
                            <Route
                                path={EPage.COUPON}
                                element={<CouponScreen />}
                            />
                            <Route
                                path={EPage.ABOUT}
                                element={<AboutScreen />}
                            />
                            <Route
                                path={EPage.CONTACT}
                                element={<ContactScreen />}
                            />
                            <Route
                                path={EPage.ACCOUNT_DEBATES}
                                element={<AccountContainer />}
                            />
                            <Route
                                path={EPage.ACCOUNT_CREDITS}
                                element={<AccountContainer />}
                            />
                            <Route
                                path={EPage.ACCOUNT_SSO}
                                element={<AccountContainer />}
                            />
                            <Route
                                path={EPage.LOGIN}
                                element={<LoginScreen />}
                            />
                            <Route
                                path={EPage.TERMS_OF_USE}
                                element={<TermsScreen />}
                            />
                            <Route
                                path={EPage.PRIVACY_POLICY}
                                element={<PrivacyScreen />}
                            />
                            <Route path={EPage.DPA} element={<DPAScreen />} />
                            <Route path="*" element={<RedirectScreen />} />
                        </Routes>
                    </Box>
                </>
            )}

            <ThemeProvider theme={snackBarTheme}>
                <MessageComponent />
            </ThemeProvider>
        </Box>
    );
};

export default App;
