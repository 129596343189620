import { USER_PARAM_A, USER_PARAM_B, USER_PARAM_C } from '../model/constant';
import { EIdentityProvider } from '../model/enum';
import { IUserAuth } from '../model/interface';

export class UserHelper {
    public static formatAuth = (
        auth: Partial<IUserAuth> | undefined
    ): string => {
        if (auth === undefined) {
            return '';
        }
        return `${USER_PARAM_A}=${encodeURIComponent(auth.accessToken!)}&${USER_PARAM_B}=${encodeURIComponent(auth.passwordToken!)}`;
    };

    public static stashIdProvider = (idProvider: EIdentityProvider) => {
        localStorage.setItem(USER_PARAM_C, idProvider);
    };

    public static getStashedIdProvider = (): EIdentityProvider | undefined => {
        return (
            (localStorage.getItem(USER_PARAM_C) as EIdentityProvider) ||
            undefined
        );
    };

    public static deleteStashedIdProvider = () => {
        localStorage.removeItem(USER_PARAM_C);
    };
}
