import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const dialogTheme = createTheme(mainTheme, {
    typography: {
        h1: {
            fontSize: '2.4rem',
        },
        body1: {
            fontSize: '1.2rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    whiteSpace: 'normal',
                },
            },
        },
    },
});

export default dialogTheme;
