import axios from 'axios';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { UserHelper } from '../../../helpers/User';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { StringHelper } from '../../../helpers/String';
import StripeComponent from './Stripe';
import { amber, pink } from '@mui/material/colors';
import { setErrorMessage, setSuccessMessage } from '../../../redux/app/slice';
import { ECurrency, EPage } from '../../../model/enum';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    Paper,
    Typography,
} from '@mui/material';
import { NumberHelper } from '../../../helpers/Number';
import { CardGiftcard, Check } from '@mui/icons-material';
import { EnvironmentHelper } from '../../../helpers/Environment';
import { TRUE } from '../../../model/constant';
import { LARGE_GAPS } from '../../../styles';

let stripePromise: Promise<Stripe | null>;
if (!EnvironmentHelper.parseAsBoolean(process.env.REACT_APP_OFFLINE)) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);
}

interface IPaymentIntentResponse {
    clientSecret?: string;
    amount?: number;
    currency?: ECurrency;
    credits?: number;
    error?: string;
}

const CREDIT_QUANTITIES = [5, 10, 20];

interface IProps {
    size: 'full' | 'minimal';
}

const PurchaseComponent: React.FC<IProps> = ({ size }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [clientSecret, setClientSecret] = useState<string | undefined>(
        undefined
    );
    const [amount, setAmount] = useState<number | undefined>();
    const [credits, setCredits] = useState<number | undefined>();
    const [purchaseQuantity, setPurchaseQuantity] = useState<
        number | undefined
    >();
    const [loading, setLoading] = useState(false);
    const [giftChecked, setGiftChecked] = useState(false);
    const apiUrl = `${process.env.REACT_APP_API_URL}/shop`;
    const userAuth = useSelector((state: RootState) => state.user.auth);
    const currency = useSelector(
        (state: RootState) => state.user.info?.currency
    );
    const recentTopic = useSelector(
        (state: RootState) => state.app.recentTopic
    );

    const composePaymentReturnUrl = () => {
        const url = new URL(
            `${window.location.origin}${EPage.PURCHASE_COMPLETE}`
        );
        if (StringHelper.isNotEmpty(recentTopic)) {
            url.searchParams.append('topic', recentTopic!);
        }
        if (giftChecked) {
            url.searchParams.append('gift', TRUE);
        }
        return url;
    };

    const fetchClientSecret = async () => {
        // Fetch the client secret from the backend
        setLoading(true);
        const response = await axios.post<IPaymentIntentResponse>(
            `${apiUrl}/payment-intent?${UserHelper.formatAuth(userAuth!)}`,
            { quantity: purchaseQuantity, gift: giftChecked }
        );
        const paymentIntentResponse: IPaymentIntentResponse = response?.data;
        if (
            paymentIntentResponse.clientSecret !== undefined &&
            paymentIntentResponse.amount !== undefined &&
            paymentIntentResponse.currency !== undefined &&
            paymentIntentResponse.credits !== undefined
        ) {
            setClientSecret(paymentIntentResponse.clientSecret);
            setAmount(paymentIntentResponse.amount);
            setCredits(paymentIntentResponse.credits);
            setLoading(false);
        } else {
            dispatch(
                setErrorMessage(
                    paymentIntentResponse.error || 'Error retrieving product'
                )
            );
            setPurchaseQuantity(undefined);
            setLoading(false);
        }
    };

    const handleGiftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setGiftChecked(checked);
        if (checked) {
            dispatch(
                setSuccessMessage(
                    'After checkout you’ll get a link you can share'
                )
            );
        }
    };

    const handleRestartClick = () => {
        setClientSecret(undefined);
        setPurchaseQuantity(0);
    };

    return size === 'minimal' ? (
        <Typography variant="body1" align="center">
            Cost: {NumberHelper.formatCurrency(100, currency)} per credit
        </Typography>
    ) : (
        <>
            {StringHelper.isEmpty(clientSecret) ? (
                <Paper elevation={8} sx={{ width: '100%' }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ gap: LARGE_GAPS }}
                    >
                        <Box
                            display="flex"
                            flexDirection={{
                                xs: 'column',
                                sm: 'column',
                                md: 'row',
                            }}
                            sx={{ xs: 2, sm: 2, md: 0 }}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="body1" align="left">
                                Select a credit package:
                            </Typography>
                            <Box display="flex" sx={{ gap: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={loading}
                                            size="small"
                                            checked={giftChecked}
                                            onChange={handleGiftChange}
                                        />
                                    }
                                    label="Buy as a gift"
                                    labelPlacement="start"
                                />
                                <CardGiftcard
                                    sx={{
                                        position: 'relative',
                                        top: 4,
                                        color: pink.A400,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            sx={{
                                gap: 2,
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'column',
                                    md: 'row',
                                },
                            }}
                        >
                            {CREDIT_QUANTITIES.map((quantity) => (
                                <Button
                                    variant={
                                        !purchaseQuantity ||
                                        purchaseQuantity === quantity
                                            ? 'contained'
                                            : 'text'
                                    }
                                    key={quantity}
                                    onClick={() =>
                                        setPurchaseQuantity(quantity)
                                    }
                                    disabled={loading}
                                    endIcon={
                                        <Check
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                width: '2.4rem',
                                                height: '2.4rem',
                                                visibility:
                                                    purchaseQuantity ===
                                                    quantity
                                                        ? 'visible'
                                                        : 'hidden',
                                            }}
                                        />
                                    }
                                    sx={{
                                        flex: 1,
                                    }}
                                >
                                    {`${quantity} credit${quantity === 1 ? '' : 's'}`}
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        sx={{ fontSize: '1.4rem' }}
                                    >
                                        {NumberHelper.formatCurrency(
                                            quantity * 100,
                                            currency
                                        )}
                                    </Typography>
                                </Button>
                            ))}
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                disabled={!purchaseQuantity || loading}
                                onClick={fetchClientSecret}
                            >
                                Checkout
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            ) : (
                <>
                    <Typography variant="caption" align="center">
                        <Link
                            onClick={handleRestartClick}
                            color="textSecondary"
                        >
                            Select a different credit package
                        </Link>
                    </Typography>
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret,
                            appearance: {
                                theme: 'night',
                                variables: {
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSizeBase: '1.3rem',
                                    colorPrimary: amber[500],
                                },
                            },
                        }}
                    >
                        <StripeComponent
                            amount={amount}
                            currency={currency}
                            credits={credits}
                            returnUrl={composePaymentReturnUrl().toString()}
                        />
                    </Elements>
                </>
            )}
            <Typography variant="caption" align="center" sx={{ opacity: 0.54 }}>
                {StringHelper.insertNonBreakingSpace(
                    'Payments are securely processed by Stripe, a trusted global leader in online payment processing used by millions of businesses worldwide. Your information is safe and encrypted.'
                )}
            </Typography>
        </>
    );
};

export default PurchaseComponent;
