import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid2, Link, Typography, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CheckCircle, Pending } from '@mui/icons-material';
import axios from 'axios';

import { IDebate, ITrendingDebate } from '../../model/interface';
import { ArrayHelper } from '../../helpers/Array';
import topicsTheme from '../../themes/topics';
import { AppHelper } from '../../helpers/App';
import { AppDispatch, RootState } from '../../redux/store';
import { UserHelper } from '../../helpers/User';
import {
    EAppState,
    EDebateFormat,
    EDebateSpice,
    EDebateStatus,
    EPage,
} from '../../model/enum';
import StartupScreen from './Startup';
import {
    setDefaultFormat,
    setDefaultSpice,
    setErrorMessage,
    setTrendingDebates,
} from '../../redux/app/slice';
import SelectorComponent from '../components/debate/Selector';
import { checkAge } from '../../redux/user/actions';
import BusyComponent from '../components/Busy';
import { ERROR_MESSAGES } from '../../model/constant';

interface IProps {
    isLatest?: boolean;
}

const TrendingScreen: React.FC<IProps> = ({ isLatest }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    const theme = useTheme();

    // from redux store
    const defaultSpice = useSelector(
        (state: RootState) => state.app.defaultSpice
    );
    const defaultFormat = useSelector(
        (state: RootState) => state.app.defaultFormat
    );
    const appState = useSelector((state: RootState) => state.app.appState);
    const trendingDebates = useSelector(
        (state: RootState) => state.app.trendingDebates
    );
    const startupSeen = useSelector(
        (state: RootState) => state.app.startupSeen
    );
    const userAuth = useSelector((state: RootState) => state.user.auth);

    // local state
    const [spice, setSpice] = useState<EDebateSpice>(defaultSpice);
    const [format, setFormat] = useState<EDebateFormat>(defaultFormat);
    const [busy, setBusy] = useState(false);
    const [activeTrendingDebates, setActiveTrendingDebates] = useState<
        ITrendingDebate[]
    >([]);

    const handleMoreClick = () => {
        navigate(isLatest ? EPage.SEARCH : EPage.CREATE);
    };

    const handleSelectSpice = async (s: EDebateSpice) => {
        if (await dispatch(checkAge(s))) {
            setSpice(s);
            dispatch(setDefaultSpice(s));
        }
    };

    const handleSelectFormat = (f: EDebateFormat) => {
        setFormat(f);
        dispatch(setDefaultFormat(f));
    };

    const handleDebateSelection = (debate: ITrendingDebate) => {
        navigate(
            AppHelper.makeDebatePath({
                key: debate.key,
                status: EDebateStatus.SHARING,
                topic: {
                    issue: debate.issue,
                },
            } as IDebate)
        );
    };

    const fetchDebates = async () => {
        setBusy(true);
        try {
            const response = await axios.get<{ debates: ITrendingDebate[] }>(
                `${apiUrl}/${isLatest ? 'latest' : 'trending'}?${UserHelper.formatAuth(userAuth!)}`
            );
            if (ArrayHelper.isNotEmpty(response?.data?.debates)) {
                dispatch(setTrendingDebates(response.data.debates));
            }
        } catch (err) {
            dispatch(setErrorMessage(ArrayHelper.shuffle(ERROR_MESSAGES)[0]));
        } finally {
            setBusy(false);
        }
    };

    useEffect(() => {
        setActiveTrendingDebates(
            trendingDebates?.filter(
                (d) => d.spice === spice && d.format === format
            ) || []
        );
    }, [spice, format, trendingDebates]);

    useEffect(() => {
        // console.log('fetchTrending?');
        if (appState === EAppState.LOADED && trendingDebates === undefined) {
            // console.log('fetchTrending');
            fetchDebates();
        }
    }, [appState, location]);

    if (!startupSeen) {
        return <StartupScreen />;
    }

    return busy ? (
        <BusyComponent />
    ) : (
        <Box
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}
            justifyContent="center"
            height={{ xs: 'auto', sm: 'auto', md: '100%' }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    gap: 4,
                    maxWidth: 1200,
                    paddingTop: 8,
                    paddingBottom: 8,
                }}
            >
                <SelectorComponent
                    action={isLatest ? 'Discover' : 'Witness'}
                    selectedSpice={spice}
                    selectedFormat={format}
                    onSelectSpice={handleSelectSpice}
                    onSelectFormat={handleSelectFormat}
                />

                <ThemeProvider theme={topicsTheme}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                            gap: 2,
                        }}
                    >
                        <Grid2 container spacing={1} justifyContent="center">
                            {activeTrendingDebates.map((debate, index) => (
                                <Grid2 key={index}>
                                    <Button
                                        color={
                                            debate.complete !== undefined
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        variant="contained"
                                        onClick={() =>
                                            handleDebateSelection(debate)
                                        }
                                        endIcon={
                                            debate.complete === true ? (
                                                <CheckCircle />
                                            ) : debate.complete === false ? (
                                                <Pending />
                                            ) : undefined
                                        }
                                    >
                                        {debate.issue}
                                    </Button>
                                </Grid2>
                            ))}
                        </Grid2>
                    </Box>
                </ThemeProvider>

                <Typography
                    variant="caption"
                    align="center"
                    color="textSecondary"
                >
                    or
                    <br />
                    <Link onClick={handleMoreClick}>
                        {isLatest
                            ? 'Search by keyword'
                            : 'Start your own debate'}
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default TrendingScreen;
